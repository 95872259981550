import React from "react";
import WeatherForm from "./WeatherForm";
import GetWeatherInfo from "./GetWeatherInfo";

const API_KEY = "968144acb5be0268d342b60b5375b8fa";

class Weather extends React.Component {
  state = {
    temp: undefined,
    city: undefined,
    country: undefined,
    humidity: undefined,
    description: undefined,
    error: undefined
  };
  getWeatherUpdate = async e => {
    e.preventDefault();
    const city = e.target.elements.city.value;
    const country = e.target.elements.country.value;
    const api_call = await fetch(
      `http://api.openweathermap.org/data/2.5/weather?q=${city},${country}&appid=${API_KEY}&units=metric`
    );
    const getData = await api_call.json();
    if (city && country) {
      console.log(getData);

      this.setState({
        temp: getData.main.temp,
        city: getData.name,
        country: getData.sys.country,
        humidity: getData.main.humidity,
        description: getData.weather[0].description,
        error: undefined
      });
    } else {
      this.setState({
        temp: undefined,
        city: undefined,
        country: undefined,
        humidity: undefined,
        description: undefined,
        error: "Please input the required details"
      });
    }
  };
  render() {
    return (
      <div>
        <WeatherForm getWeatherUpdate={this.getWeatherUpdate} />
        <GetWeatherInfo
          temp={this.state.temp}
          city={this.state.city}
          country={this.state.country}
          humidity={this.state.humidity}
          description={this.state.description}
          error={this.state.error}
        />
      </div>
    );
  }
}

export default Weather;
