import React, { Component } from "react";
import { ReactifyChartColors } from "../../util/ReactifyChartColors";

import Chart from "react-apexcharts";

const colors = ReactifyChartColors();

class RadarMultipleSeries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        colors: [colors.ReactifyChartColor1, colors.ReactifyChartColor2],
        chart: {
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          }
        },
        labels: ["Glasses", "Shoes", "Shirts", "Jackets", "Coat", "T-Shirts"],
        stroke: {
          width: 2
        },
        fill: {
          opacity: 0
        },
        markers: {
          size: 0
        }
      },
      series: [
        {
          name: "Glasses",
          data: [80, 50, 30, 40, 100, 20]
        },
        {
          name: "T-Shirts",
          data: [20, 30, 40, 80, 20, 80]
        }
      ]
    };
  }
  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="radar"
        width="100%"
        height="300px"
      />
    );
  }
}

export default RadarMultipleSeries;
