import React from "react";
import TodoForm from "./TodoForm";
import Todo from "./Todo";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Media,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Progress,
  Form,
  FormGroup,
  Button,
  CardFooter,
  Label,
  Input,
  Container,
  Col,
  CustomInput,
  CardHeader
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

/*
  TodoMVC
  1. add todo
  2. display todos
  3. cross off todo
  4. show number of active todos
  5. filter all/active/complete
  6. delete todo
  7. delete all complete
    7.1 only show if atleast one is complete
  8. button to toggle all on/off
*/

export default class TodoList extends React.Component {
  state = {
    todos: [],
    todoToShow: "all",
    toggleAllComplete: true
  };

  addTodo = todo => {
    this.setState(state => ({
      todos: [todo, ...state.todos]
    }));
  };

  toggleComplete = id => {
    this.setState(state => ({
      todos: state.todos.map(todo => {
        if (todo.id === id) {
          // suppose to update
          return {
            ...todo,
            complete: !todo.complete
          };
        } else {
          return todo;
        }
      })
    }));
  };

  updateTodoToShow = s => {
    this.setState({
      todoToShow: s
    });
  };

  handleDeleteTodo = id => {
    this.setState(state => ({
      todos: state.todos.filter(todo => todo.id !== id)
    }));
  };

  removeAllTodosThatAreComplete = () => {
    this.setState(state => ({
      todos: state.todos.filter(todo => !todo.complete)
    }));
  };

  render() {
    let todos = [];

    if (this.state.todoToShow === "all") {
      todos = this.state.todos;
    } else if (this.state.todoToShow === "active") {
      todos = this.state.todos.filter(todo => !todo.complete);
    } else if (this.state.todoToShow === "complete") {
      todos = this.state.todos.filter(todo => todo.complete);
    }

    return (
      <div>
        <div className="text-small text-muted mt-2">
          Remaning Tasks:{" "}
          {this.state.todos.filter(todo => !todo.complete).length}
        </div>
        <div className="dashboard-tasks">
          <PerfectScrollbar
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <CardBody>
              <TodoForm onSubmit={this.addTodo} />
              {todos.map(todo => (
                <Todo
                  key={todo.id}
                  toggleComplete={() => this.toggleComplete(todo.id)}
                  onDelete={() => this.handleDeleteTodo(todo.id)}
                  todo={todo}
                />
              ))}
            </CardBody>
          </PerfectScrollbar>
        </div>
        <div>
          <CardFooter>
            <span
              className="todobtn"
              color="link"
              onClick={() => this.updateTodoToShow("all")}
            >
              All
            </span>
            <span
              className="todobtn"
              color="link"
              onClick={() => this.updateTodoToShow("active")}
            >
              Active
            </span>
            <span
              className="todobtn"
              color="link"
              onClick={() => this.updateTodoToShow("complete")}
            >
              Complete
            </span>
            <span
              className="todobtn"
              color="link"
              onClick={() =>
                this.setState(state => ({
                  todos: state.todos.map(todo => ({
                    ...todo,
                    complete: state.toggleAllComplete
                  })),
                  toggleAllComplete: !state.toggleAllComplete
                }))
              }
            >
              Mark All
            </span>
            {this.state.todos.some(todo => todo.complete) ? (
              <div>
                <span
                  className="todobtn"
                  color="link"
                  onClick={this.removeAllTodosThatAreComplete}
                >
                  Remove All
                </span>
              </div>
            ) : null}
          </CardFooter>
        </div>

        <div />
      </div>
    );
  }
}
