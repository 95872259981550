import React from "react";
import shortid from "shortid";
import { Form, FormGroup, Button, Input } from "reactstrap";

export default class TodoForm extends React.Component {
  state = {
    text: ""
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.onSubmit({
      id: shortid.generate(),
      text: this.state.text,
      complete: false
    });
    this.setState({
      text: ""
    });
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Input
            name="text"
            value={this.state.text}
            onChange={this.handleChange}
            placeholder="Add Task"
          />
        </FormGroup>
        <Button
          className="btn-block todobtn bg-theme-1"
          onClick={this.handleSubmit}
        >
          Add
        </Button>
      </Form>
    );
  }
}
