import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {Button, ButtonGroup, Card, CardBody, CardTitle, Row} from "reactstrap";
import ApiClient from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";

const ENTRY_FIELDS = [
    "name",
    "email_address",
    "mobile_number",
    "address",
    "city",
    "region",
    "country"
]

class PromoList extends Component {
    state = {
        promos: [],
        showButtonGroup: null
    }

    promoApi = new ApiClient('promo', this.authenticationErrorHandler.bind(this))

    constructor(props) {
        super(props);

        this.history = props.history;
    }

    authenticationErrorHandler() {
        clearAuthTokens();
        this.history.replace('/login/')
    }

    componentDidMount() {
        this.promoApi.list().then(response => {
            const responseData = response.data
            this.setState({
                promos: [...responseData]
            })
        })

    }

    renderButtonGroup(promo) {
        const buttonWidth = "55px";

        if (this.state.showButtonGroup === 'edit') {
            return <>
                <Button className="px-0" style={{width: buttonWidth}} color="danger" onClick={() => {
                    this.setState({showButtonGroup: null})
                }}>
                    <span className="fa fa-times"/> <br/>
                    <small>edit</small>
                </Button>

                <Button className="px-0" style={{width: buttonWidth}} color="secondary" outline onClick={() => {
                    this.history.push('/app/promo/' + promo.id + '/')
                }}>
                    <span className="fa fa-pen"/> <br/>
                    <small>promo</small>
                </Button>
                <Button className="px-0" style={{width: buttonWidth}} color="secondary" outline onClick={() => {
                    this.history.push('/app/promo/' + promo.id + '/entryMapping/')
                }} title={"Entry Property Mapping"}>
                    <span className="fa fa-map-signs"/> <br/>
                    <small>prop. map</small>
                </Button>

                <Button className="px-0" style={{width: buttonWidth}} color="secondary" outline disabled>
                    <span className="fa fa-qrcode"/> <br/>
                    <small>code</small>
                </Button>
                <Button className="px-0" style={{width: buttonWidth}} color="secondary" outline disabled>
                    <span className="fa fa-users"/> <br/>
                    <small>users</small>
                </Button>
            </>
        } else if (this.state.showButtonGroup === 'codes') {

            return <>
                <Button className="px-0" style={{width: buttonWidth}} color="light" outline disabled={true}>
                </Button>

                <Button className="px-0" style={{width: buttonWidth}} color="danger" onClick={() => {
                    this.setState({showButtonGroup: null})
                }}>
                    <span className="fa fa-times"/> <br/>
                    <small>codes</small>
                </Button>

                <Button className="px-0" style={{width: buttonWidth}} color="secondary" outline disabled>
                    <span className="fa fa-stream"/> <br/>
                    <small>list</small>
                </Button>
                <Button className="px-0" style={{width: buttonWidth}} color="secondary" outline onClick={() => {
                    this.history.push('/app/promo/' + promo.id + '/disputes/')
                }} title="Disputes">
                    <span className="fa fa-exclamation-triangle"/> <br/>
                    <small>dispute</small>
                </Button>
                <Button className="px-0" style={{width: buttonWidth}} color="light" outline disabled={true}>
                </Button>
            </>
        } else if (this.state.showButtonGroup === 'entries') {

            return <>
                <Button className="px-0" style={{width: buttonWidth}} color="light" outline disabled={true}></Button>
                <Button className="px-0" style={{width: buttonWidth}} color="light" outline disabled={true}></Button>

                <Button style={{width: buttonWidth, textOverflow: "clip"}} color="danger" onClick={() => {
                    this.setState({showButtonGroup: null})
                }}>
                    <span className="fa fa-times"/> <br/>
                    <small>entry</small>
                </Button>

                <Button className="px-0" style={{width: buttonWidth}} color="secondary" outline onClick={() => {
                    this.history.push('/app/promo/' + promo.id + '/entries/')
                }} title={"Entries"}>
                    <span className="fa fa-ticket-alt"/> <br/>
                    <small>entries</small>
                </Button>
                <Button className="px-0" style={{width: buttonWidth}} color="secondary" outline disabled>
                    <span className="fa fa-users"/> <br/>
                    <small>profiles</small>
                </Button>
            </>
        }

        // DEFAULT buttons
        return <>
            <Button className="px-0" style={{width: buttonWidth}} color="primary" onClick={() => {
                this.setState({showButtonGroup: 'edit'})
            }}>
                <span className="fa fa-pen"/> <br/>
                <small>edit</small>
            </Button>
            <Button className="px-0" style={{width: buttonWidth}} color="primary" onClick={() => {
                this.setState({showButtonGroup: 'codes'})
            }}>
                <span className="fa fa-qrcode"/><br/>
                <small>codes</small>
            </Button>
            <Button className="px-0" style={{width: buttonWidth}} color="primary"  onClick={() => {
                this.setState({showButtonGroup: 'entries'})
            }}>
                <span className="fa fa-ticket-alt"/><br/>
                <small>entry</small>
            </Button>
            <Button className="px-0" style={{width: buttonWidth}} color="primary" disabled>
                <span className="fa fa-chart-bar"/><br/>
                <small>report</small>
            </Button>
            <Button className="px-0" style={{width: buttonWidth}} color="primary" onClick={() => {
                this.history.push('/app/promo/' + promo.id + '/winners/')
            }}>
                <span className="fa fa-trophy"/><br/>
                <small>draw</small>
            </Button>
        </>
    }

    render() {
        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Button size="sm" className="float-right"  onClick={() => {
                                this.history.push('/app/promo/new/')
                            }}>
                                <span className="fa fa-plus"/> New Promo
                            </Button>
                            <h2>Promos</h2>
                        </CardTitle>


                        {
                            this.state.promos.map(promo => <>
                                <Row key={"promoRow" + promo.id}>
                                    <Colxx className="my-auto">
                                        <strong>{promo.title}</strong>
                                    </Colxx>
                                    <Colxx className="my-auto" title={promo.date_start + " to " + promo.date_end}>
                                        {promo.duration}{" "}
                                        <i
                                            className="fa fa-info-circle font-color-1 mr-1"
                                            title={promo.date_start + " to " + promo.date_end}
                                        />
                                    </Colxx>
                                    <Colxx className="p-0 text-center">
                                        <ButtonGroup size="xs">
                                            {this.renderButtonGroup(promo)}
                                        </ButtonGroup>
                                    </Colxx>
                                </Row>
                                <hr/>

                            </>)
                        }
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }


}

export default PromoList