import React, { Component } from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {Button, Card, CardBody, CardTitle, Form, FormGroup, Input, Label, Row, Spinner} from "reactstrap";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import {injectIntl} from "react-intl";
import ApiClient from "../../api/ApiClient";

import moment from "moment";

const ENTRY_FIELDS = [
    "name",
    "email_address",
    "mobile_number",
    "address",
    "city",
    "region",
    "country",
    "raw_code",
    "attachments",
]

const selectEntryFields = ENTRY_FIELDS.map(o => ({
    label: o.replace('_', ' ').split(' ')
        .map(i => i[0].toUpperCase() + i.substring(1).toLowerCase()).join(' '),
    value: o,
    key: o
}))


class MappingInput extends Component {
    handleChangeFrom(e) {
        this.props.handleChangeFrom(e.target.value)
    }
    handleChangeTo(selectedOption) {
        this.props.handleChangeTo(selectedOption.value)
    }

    render() {
        const toOption = selectEntryFields.filter(o => o.value === this.props.to)[0]

        return <Row form className="mb-4">
            <Colxx md={5} sm={12} xs={12} xxs={12} className="my-auto">
                <FormGroup className="mb-0">
                    <Input
                        type="text"
                        name="promoTitle"
                        placeholder="Key from Mobile Monkey"
                        value={this.props.from}
                        onChange={this.handleChangeFrom.bind(this)}
                    />
                </FormGroup>
            </Colxx>
            <Colxx className="my-auto text-center">
                <span className="fa fa-arrow-right d-none d-md-block"/>
                <span className="fa fa-level-up-alt fa-rotate-90 d-md-none d-xs-block d-sm-block"/>
            </Colxx>
            <Colxx md={5} sm={10} xs={10} xxs={8}>
                <FormGroup className="mb-0">
                    <Select
                        components={{ Input: CustomSelect }}
                        className="react-select"
                        classNamePrefix="react-select"
                        name="form-field-name"
                        value={toOption}
                        placeholder="Promo Entry property"
                        onChange={this.handleChangeTo.bind(this)}
                        options={this.props.availableOptions}
                    />
                </FormGroup>
            </Colxx>
            <Colxx className="my-auto text-center">
                <Button color="danger" size="xs" onClick={() => {
                    this.props.delete()
                }}>
                    <span className="fa fa-trash"/><br/>
                    delete
                </Button>
            </Colxx>
        </Row>
    }
}


class PromoEntryMapping extends Component {
    state = {
        mapping: [],
        originalMapping: [],
        promoTitle: "",
        blockingStatus: "Loading..."
    }

    promoApi = new ApiClient('promo')

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { match } = this.props;
        const promoID = match.params.promoID

        if (promoID) {
            this.promoApi.getItem(promoID).then(this.handleApiResponse.bind(this))
        }
    }

    handleApiResponse(response) {
        const promoData = response.data
        const entryMapping = Object.keys(promoData.entry_mapping).map(
            (k) => ({
                key: k,
                value: promoData.entry_mapping[k]
            })
        ).reduce((acc, o, idx) => {
            acc[idx] = o;
            return acc;
        }, {});
        this.setState({
            mapping: entryMapping,
            originalMapping: entryMapping,
            promoTitle: promoData.title,
            blockingStatus: null,
        })
    }

    handleSave() {
        const { match } = this.props;
        const promoID = match.params.promoID
        this.setState({
            blockingStatus: "Saving...",
        }, () => {

            this.promoApi.patchItem(promoID, {
                "entry_mapping": Object.values(this.state.mapping).reduce((acc, o) => {
                    acc[o.key] = o.value;
                    return acc
                }, {})
            }).then(this.handleApiResponse.bind(this))
        })
    }

    isDataChanged() {
        const newData = {...this.state.mapping}
        const originalData = {...this.state.originalMapping}

        const isEqual = JSON.stringify(newData) === JSON.stringify(originalData)

        return !isEqual
    }

    handleChangeMappingKey(idx, newKey) {
        this.setState(prevState => {
            return {
                ...prevState,
                mapping: {
                    ...prevState.mapping,
                    [idx]: {
                        ...prevState.mapping[idx],
                        key: newKey
                    }
                }
            }
        })
    }
    handleChangeMappingValue(idx, newValue) {
        this.setState(prevState => {
            return {
                ...prevState,
                mapping: {
                    ...prevState.mapping,
                    [idx]: {
                        ...prevState.mapping[idx],
                        value: newValue
                    }
                }
            }
        })
    }

    handleDelete(idx) {
        this.setState(prevState => {
            return {
                ...prevState,
                mapping: {
                    ...Object.keys(prevState.mapping).filter(k => k !== idx).map(k => prevState.mapping[k])
                }
            }
        })
    }

    addRow() {
        this.setState(prevState => {
            return {
                ...prevState,
                mapping: {
                    ...prevState.mapping,
                    [Math.max(...Object.keys(prevState.mapping).map(k => parseInt(k))) + 1]: {
                        key: "",
                        value: ""
                    }
                }
            }
        })
    }

    renderMap(idx) {
        const {key, value} = this.state.mapping[idx]
        const usedValues = Object.values(this.state.mapping).map(o => o.value)
        const availableValues = selectEntryFields.filter(o => usedValues.indexOf(o.value) === -1 || o.value === value)

        return <MappingInput
            from={key}
            to={value}
            availableOptions={availableValues}
            key={"map-" + idx}
            handleChangeFrom={this.handleChangeMappingKey.bind(this, idx)}
            handleChangeTo={this.handleChangeMappingValue.bind(this, idx)}
            delete={this.handleDelete.bind(this, idx)}
        />
    }

    render() {
        if (this.state.blockingStatus) {
            return <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h2>Editing Entry Mapping for <strong>{this.state.promoTitle}</strong></h2>
                            </CardTitle>

                            <Row>
                                <Colxx className="text-center my-auto">
                                    <Spinner animation="growing">
                                        { this.state.blockingStatus }
                                    </Spinner>
                                </Colxx>
                            </Row>

                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
        }
        const usedFieldValues = Object.values(this.state.mapping).map(o => o.value)
        const unusedFieldValues = selectEntryFields.filter(o => usedFieldValues.indexOf(o.value) === -1)
        const valuesValid = Object.values(this.state.mapping).every(o => !!o.value && !!o.key)

        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h2>Editing Entry Mapping for <strong>{this.state.promoTitle}</strong></h2>
                        </CardTitle>
                        <Row className="mb-4">
                            <Colxx>
                                Entry mapping manages how the attributes received from Mobile Monkey data are mapped into entry properties.
                                <ul>
                                    <li>
                                        By default, platform will try to find same named Mobile Monkey attribute and map it into entry properties.
                                        For different attribute names and entry property names, add a mapping.
                                    </li>
                                    <li>
                                        Unmapped entry properties will not be filled in. Be careful if some per-promo required properties, such as code is not mapped. This may mark the entry as invalid.
                                    </li>
                                    <li>
                                        Unmapped Mobile Monkey API attributes will still be saved in the database and can be used on filtering and exports.
                                    </li>
                                </ul>

                            </Colxx>
                        </Row>
                        <Form>
                            <Row form className="mb-4">
                                <Colxx md={5} sm={12} xs={12} xxs={12} className="my-auto">
                                    <strong>Key from Mobile Monkey</strong>
                                </Colxx>
                                <Colxx className="my-auto text-center">
                                    <span className="fa fa-arrow-right d-none d-md-block"/>
                                    <span className="fa fa-level-up-alt fa-rotate-90 d-md-none d-xs-block d-sm-block"/>
                                </Colxx>
                                <Colxx md={5} sm={10} xs={10} xxs={8}>
                                    <FormGroup className="mb-0">
                                        <strong>Promo Entry property</strong>
                                    </FormGroup>
                                </Colxx>

                                <Colxx className="my-auto text-center">
                                </Colxx>
                            </Row>

                            { Object.keys(this.state.mapping).map( k => this.renderMap(k)) }

                            <Button color="primary" className="mt-2"
                                    onClick={this.addRow.bind(this)}
                                    disabled={unusedFieldValues.length === 0 || usedFieldValues.length >= selectEntryFields.length}>
                                <span className="fa fa-plus"/> Add
                            </Button>
                            <Button color="primary" className="mt-2" disabled={!this.isDataChanged() || !valuesValid} onClick={this.handleSave.bind(this)}>
                                <span className="fa fa-save"/> Save
                            </Button>
                        </Form>
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }
}

export default PromoEntryMapping;