import React, {Component} from 'react';
import {Row} from "reactstrap";
import {Colxx} from "../../components/CustomBootstrap";
import {dictToRows, keyValueToRow} from "../../util/RenderUtils";


export default class EntryDetail extends Component {
    render() {
        if (!this.props.entry) {
            return <>Loading</>
        }

        return <>
            {keyValueToRow("Code", <b>{this.props.entry.raw_code}</b>)}
            {
                dictToRows(this.props.entry, ['raw_code', 'id', 'entry_errors', 'has_error', 'is_active'])
            }
            {
                this.props.entry.attachments.length > 0 && this.props.entry.attachments[0].file && <>
                    <Row className="mt-4">
                        <Colxx><strong>Attachment</strong></Colxx>
                        <colxx className="text-right pr-3">
                            <a href={this.props.entry.attachments[0].file} target="_blank">
                                Open in a new tab <span className="fa fa-external-link-alt"/>
                            </a>
                        </colxx>
                    </Row>
                    <Row>
                        <Colxx>
                            <img src={this.props.entry.attachments[0].file} className="img-fluid"/>
                        </Colxx>
                    </Row>
                </>
            }

            <Row className="mt-4">
                <Colxx><strong>Original Data</strong></Colxx>
            </Row>
            <Row className="px-2">
                <Colxx style={{textOverflow: "ellipsis", overflow: "hidden"}} className="border-1px m-2 p-2">

                    {
                        dictToRows(this.props.entry.raw_data)
                    }
                </Colxx>
            </Row>
        </>
    }
}