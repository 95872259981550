import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PromoForm from "./promoForm";
import PromoList from "./promoList";
import PromoEntryMapping from "./promoEntryMapping";
import EntryList from "./entryList";
import PromoDraw from "./promoDraw";
import DisputeList from "./disputeList";


export default class Ui extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { match } = this.props;

        return (
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
                <Route path={`${match.url}/list`} component={PromoList} />
                <Route key="new-promo" path={`${match.url}/new`} component={PromoForm} />

                <Route path={`${match.url}/:promoID/entryMapping`} component={PromoEntryMapping} />

                <Route key="promo-entries" path={`${match.url}/:promoID/entries`} component={EntryList} />
                <Route key="all-entries" path={`${match.url}/entries`} component={EntryList} />

                <Route key="promo-disputes" path={`${match.url}/:promoID/disputes`} component={DisputeList} />
                <Route key="all-disputes" path={`${match.url}/disputes`} component={DisputeList} />

                <Route path={`${match.url}/:promoID/winners`} component={PromoDraw} />

                <Route key="edit-promo" path={`${match.url}/:promoID`} component={PromoForm} />


                <Redirect to="/error" />
            </Switch>
        );
    }
}