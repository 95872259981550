import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {Button, Card, CardBody, CardTitle, CustomInput, Form, FormGroup, Input, Label, Row, Table} from "reactstrap";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import {injectIntl} from "react-intl";
import ApiClient from "../../api/ApiClient";

import moment from "moment";
import {clearAuthTokens} from "axios-jwt";

const DEFAULT_USER_DATA = {
    'username': '',
    'email': '',
    'is_active': false,
    profile: {
        description: ''
    }
}


function testEmail(email) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

function compareArray(array1, array2) {
    const array2Sorted = array2.slice().sort();
    return array1.length === array2.length && array1.slice().sort().every(function (value, index) {
        return value === array2Sorted[index];
    });
}

const VIEW_EDIT_WEBHOOK = "manage_existing_webhook";
const VIEW_EDIT_PROMO = "manage_existing_promo";
const VIEW_EDIT_USER = "manage_existing_user";
const CREATE_DELETE_WEBHOOK = "manage_webhook";
const CREATE_DELETE_PROMO = "manage_promo";
const CREATE_DELETE_USER = "manage_user";

const PRESET_PERMISSIONS = {
    "Manager": [
        VIEW_EDIT_WEBHOOK,
        VIEW_EDIT_PROMO,
        VIEW_EDIT_USER
    ],
    "Administrator": [
        CREATE_DELETE_WEBHOOK,
        CREATE_DELETE_PROMO,
        CREATE_DELETE_USER
    ],
    "customize": [],
}


class UserPromoPermissionForm extends Component {
    state = {
        promoPermissions: {},
        originalPromoPermissions: {}
    }

    userApi = new ApiClient('user', this.authenticationErrorHandler.bind(this))


    constructor(props) {
        super(props);

        this.history = props.history;
    }

    authenticationErrorHandler(error) {
        if (error.response && error.response.status === 403) {
            clearAuthTokens();
            this.history.replace('/login/')
        } else {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }

        console.log(error.config);
    }

    componentDidMount() {
        const userID = this.props.userID

        if (userID) {
            this.userApi.getItem(userID + "/promo_permissions").then(this.processResponseData.bind(this))
        }
    }

    processResponseData(response) {
        this.setState({
            promoPermissions: JSON.parse(JSON.stringify(response.data.promos)),
            originalPromoPermissions: JSON.parse(JSON.stringify(response.data.promos))
        })
    }

    togglePermission(promoID, permissionName) {
        this.setState(prevState => {
            return {
                ...prevState,
                promoPermissions: {
                    ...prevState.promoPermissions,
                    [promoID]: {
                        ...prevState.promoPermissions[promoID],
                        permissions: {
                            ...prevState.promoPermissions[promoID].permissions,
                            [permissionName]: !prevState.promoPermissions[promoID].permissions[permissionName]
                        }
                    }
                }
            }
        })
    }

    isDataChanged() {
        return JSON.stringify(this.state.promoPermissions) !== JSON.stringify(this.state.originalPromoPermissions)
    }

    handleSave() {
        const userID = this.props.userID

        if (userID) {
            this.userApi.updateItem(
                userID + "/promo_permissions",
                {promo: JSON.parse(JSON.stringify(this.state.promoPermissions))}
            ).then(this.processResponseData.bind(this))
        }
    }


    render() {
        if (Object.values(this.state.promoPermissions).length <= 0) {
            return ""
        }
        const permissionNames = Object.keys(Object.values(this.state.promoPermissions)[0].permissions)
        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h4>Per Promo Permission</h4>
                        </CardTitle>


                        <Table hover>
                            <thead>
                            <tr>
                                <th>Promo</th>
                                {
                                    permissionNames.map(
                                        permissionName => <th className="text-center">
                                            {permissionName.replace(/_/g, ' ').toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())}
                                        </th>
                                    )
                                }
                            </tr>
                            </thead>
                            {
                                Object.values(this.state.promoPermissions).map(
                                    promoPermission => <tr>
                                        <th>{promoPermission.title}</th>
                                        {
                                            permissionNames.map(
                                                permissionName => <td  className="text-center">
                                                    <CustomInput
                                                        type="checkbox"
                                                        id="CustomInlineCheckbox"
                                                        onChange={this.togglePermission.bind(this, promoPermission.id, permissionName)}
                                                        checked={promoPermission.permissions[permissionName]}
                                                        inline
                                                    />
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                            <tbody>
                            </tbody>
                        </Table>


                        <Button color="primary" className="mt-2"
                                disabled={!this.isDataChanged()}
                                onClick={this.handleSave.bind(this)}>
                            Save
                        </Button>
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }
}


class UserPermissionForm extends Component {

    constructor(props) {
        super(props);

        let defaultPreset = "customize";
        let permissions = [];

        if (props.permissionDisplay.indexOf("Administrator") > -1) {
            defaultPreset = 'Administrator';
            permissions = PRESET_PERMISSIONS[defaultPreset]
        } else if (props.permissionDisplay.indexOf("Manager") > -1) {
            defaultPreset = 'Manager';
            permissions = PRESET_PERMISSIONS[defaultPreset]
        } else {
            if (props.permissionDisplay.indexOf("Manage existing Promos") > -1) {
                permissions.push(VIEW_EDIT_PROMO)
            }
            if (props.permissionDisplay.indexOf("Manage existing Regular Users") > -1) {
                permissions.push(VIEW_EDIT_USER)
            }
            if (props.permissionDisplay.indexOf("Manage existing API Connections") > -1) {
                permissions.push(VIEW_EDIT_WEBHOOK)
            }
            if (props.permissionDisplay.indexOf("Manage Promos") > -1) {
                permissions.push(CREATE_DELETE_PROMO)
            }
            if (props.permissionDisplay.indexOf("Manage Regular Users") > -1) {
                permissions.push(CREATE_DELETE_USER)
            }
            if (props.permissionDisplay.indexOf("Manage API Connections") > -1) {
                permissions.push(CREATE_DELETE_WEBHOOK)
            }
        }

        this.state = {
            selectedPreset: defaultPreset,
            appliedPreset: defaultPreset,
            originalPermissions: [...permissions],
            currentPermissions: [...permissions],
        }
    }

    handlePresetChange(presetSelectedOption) {
        this.setState({
            selectedPreset: presetSelectedOption.value
        })
    }

    togglePermission(permissionName) {
        this.setState(prevState => {
            if (prevState.currentPermissions.indexOf(permissionName) > -1) {
                // existing
                return {
                    ...prevState,
                    currentPermissions: [...prevState.currentPermissions.filter(p => p !== permissionName)],
                    selectedPreset: "customize"
                }
            } else {
                // add
                return {
                    ...prevState,
                    currentPermissions: [
                        ...prevState.currentPermissions,
                        permissionName
                    ],
                    selectedPreset: "customize"
                }
            }
        })
    }

    applyPreset() {
        const selectedPermissions = PRESET_PERMISSIONS[this.state.selectedPreset]
        console.log(this.state.selectedPreset, selectedPermissions)
        this.setState({
            currentPermissions: [...selectedPermissions]
        })
    }

    isDataChanged() {
        const newPermissions = this.newPermissions()
        const oldPermissions = [...this.state.originalPermissions]


        return !compareArray(newPermissions, oldPermissions)
    }

    newPermissions() {
        const newPermissions = [...this.state.currentPermissions]
        // do cleanup on new permissions
        if (newPermissions.indexOf(CREATE_DELETE_PROMO) > -1 && newPermissions.indexOf(VIEW_EDIT_PROMO) > -1) {
            newPermissions.splice(newPermissions.indexOf(VIEW_EDIT_PROMO), 1)
        }

        if (newPermissions.indexOf(CREATE_DELETE_USER) > -1 && newPermissions.indexOf(VIEW_EDIT_USER) > -1) {
            newPermissions.splice(newPermissions.indexOf(VIEW_EDIT_USER), 1)
        }

        if (newPermissions.indexOf(CREATE_DELETE_WEBHOOK) > -1 && newPermissions.indexOf(VIEW_EDIT_WEBHOOK) > -1) {
            newPermissions.splice(newPermissions.indexOf(VIEW_EDIT_WEBHOOK), 1)
        }

        return newPermissions
    }

    handleSave() {
        this.props.save(this.newPermissions())
    }

    render() {
        const presetOptions = [
            {label: "Administrator", value: "Administrator"},
            {label: "Manager", value: "Manager"}
        ]

        const presetSelectedOption = this.state.selectedPreset === 'customize' ? null : presetOptions.filter(o => o.value === this.state.selectedPreset)[0]

        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h4>System Permissions</h4>
                        </CardTitle>

                        <Row className="mb-4">
                            <Colxx md={6}>
                                <Select
                                    components={{Input: CustomSelect}}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    value={presetSelectedOption}
                                    onChange={this.handlePresetChange.bind(this)}
                                    placeholder="Select Preset"
                                    options={presetOptions}
                                />
                            </Colxx>
                            <Colxx>
                                <Button
                                    onClick={this.applyPreset.bind(this)}
                                    disabled={
                                        this.state.selectedPreset === 'customize'
                                    }>
                                    Apply Preset
                                </Button>
                            </Colxx>
                        </Row>

                        <Table hover>
                            <thead>
                            <tr>
                                <th>Component</th>
                                <th className="text-center">Create and Delete</th>
                                <th className="text-center">View and Edit</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr>
                                <th>API Connections</th>
                                <td className="text-center">
                                    <CustomInput
                                        type="checkbox"
                                        id="CustomInlineCheckbox"
                                        onChange={this.togglePermission.bind(this, CREATE_DELETE_WEBHOOK)}
                                        checked={this.state.currentPermissions.indexOf(CREATE_DELETE_WEBHOOK) > -1}
                                        inline
                                    />
                                </td>
                                <td className="text-center">
                                    <CustomInput
                                        type="checkbox"
                                        id="CustomInlineCheckbox"
                                        onChange={this.togglePermission.bind(this, VIEW_EDIT_WEBHOOK)}
                                        disabled={this.state.currentPermissions.indexOf(CREATE_DELETE_WEBHOOK) > -1}
                                        checked={this.state.currentPermissions.indexOf(VIEW_EDIT_WEBHOOK) > -1 || this.state.currentPermissions.indexOf(CREATE_DELETE_WEBHOOK) > -1}
                                        inline
                                    />
                                </td>
                            </tr>

                            <tr>
                                <th>Promo *</th>
                                <td className="text-center">
                                    <CustomInput
                                        type="checkbox"
                                        id="CustomInlineCheckbox"
                                        onChange={this.togglePermission.bind(this, CREATE_DELETE_PROMO)}
                                        checked={this.state.currentPermissions.indexOf(CREATE_DELETE_PROMO) > -1}
                                        inline
                                    />
                                </td>
                                <td className="text-center">
                                    <CustomInput
                                        type="checkbox"
                                        id="CustomInlineCheckbox"

                                        onChange={this.togglePermission.bind(this, VIEW_EDIT_PROMO)}
                                        disabled={this.state.currentPermissions.indexOf(CREATE_DELETE_PROMO) > -1}
                                        checked={this.state.currentPermissions.indexOf(VIEW_EDIT_PROMO) > -1 || this.state.currentPermissions.indexOf(CREATE_DELETE_PROMO) > -1}
                                        inline
                                    />
                                </td>
                            </tr>

                            <tr>
                                <th>Users</th>
                                <td className="text-center">
                                    <CustomInput
                                        type="checkbox"
                                        id="CustomInlineCheckbox"
                                        onChange={this.togglePermission.bind(this, CREATE_DELETE_USER)}
                                        checked={this.state.currentPermissions.indexOf(CREATE_DELETE_USER) > -1}
                                        inline
                                    />
                                </td>
                                <td className="text-center">
                                    <CustomInput
                                        type="checkbox"
                                        id="CustomInlineCheckbox"

                                        onChange={this.togglePermission.bind(this, VIEW_EDIT_USER)}
                                        disabled={this.state.currentPermissions.indexOf(CREATE_DELETE_USER) > -1}
                                        checked={this.state.currentPermissions.indexOf(VIEW_EDIT_USER) > -1 || this.state.currentPermissions.indexOf(CREATE_DELETE_USER) > -1}
                                        inline
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                        <p>
                        * If System Permission is set for Promo, user does not need per-promo access. The user will have
                        access to all promos.
                        </p>
                        <Button color="primary" className="mt-2"
                                disabled={!this.isDataChanged()}
                                onClick={this.handleSave.bind(this)}>
                            Save
                        </Button>
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }
}


class UserForm extends Component {
    state = {
        currentData: {...DEFAULT_USER_DATA},
        originalData: {...DEFAULT_USER_DATA},
        typingTimeoutId: 0,
        usernameStatus: true,
        usernameStatusMessage: '',
        permissionsLoaded: false
    }

    userApi = new ApiClient('user', this.authenticationErrorHandler.bind(this))

    constructor(props) {
        super(props);

        this.history = props.history;
    }

    authenticationErrorHandler(error) {
        if (error.response && error.response.status === 403) {
            clearAuthTokens();
            this.history.replace('/login/')
        } else {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }

        console.log(error.config);
    }

    componentDidMount() {
        const {match} = this.props;
        const userID = match.params.userID

        if (userID) {
            this.userApi.getItem(userID).then(this.processResponseData.bind(this))
        }
    }

    componentWillUnmount() {
        if (this.state.typingTimeoutId) {
            clearTimeout(this.state.typingTimeoutId)
        }
    }

    isDataChanged() {
        const isEqual = JSON.stringify(this.state.originalData) === JSON.stringify(this.state.currentData)
        return !isEqual;
    }

    isDataValid() {
        return testEmail(this.state.currentData.email) && this.state.usernameStatus
    }

    processResponseData(response) {
        this.setState({currentData: response.data, originalData: response.data, permissionsLoaded: true})
    }

    handleCurrentDataChange(dataKey, e) {
        this.setState(prevState => {
            return {
                ...prevState,
                currentData: {
                    ...prevState.currentData,
                    [dataKey]: e.target.value
                }
            }
        })
    }

    handleUsernameChange(e) {
        const self = this;
        this.setState(prevState => {
            if (this.state.typingTimeoutId) {
                clearTimeout(this.state.typingTimeoutId)
            }
            return {
                ...prevState,
                currentData: {
                    ...prevState.currentData,
                    username: e.target.value
                },
                typingTimeoutId: setTimeout(function () {
                    self.checkUsernameAvailability()
                }, 1000),
                usernameStatusMessage: "Validating username..."
            }
        })
    }

    checkUsernameAvailability() {
        clearTimeout(this.state.typingTimeoutId)
        this.userApi.get('username_availability', {
            params: {
                "original_username": this.state.originalData.username,
                "new_username": this.state.currentData.username
            }
        }).then(response => {
            this.setState({
                "usernameStatus": response.data.status === "ok",
                usernameStatusMessage: response.data.message
            })
        })
    }

    handleDescriptionChange(e) {
        console.log(e.target.value)
        this.setState(prevState => {
            return {
                ...prevState,
                currentData: {
                    ...prevState.currentData,
                    profile: {
                        ...prevState.currentData.profile,
                        description: e.target.value
                    }
                }
            }
        })
    }

    handleSave() {
        const {match} = this.props;
        const userID = match.params.userID
        if (userID) {
            this.userApi.updateItem(userID, {...this.state.currentData}).then(this.processResponseData.bind(this))
        } else {
            this.userApi.create({...this.state.currentData}).then(response => {
                const newUserId = response.data.id;
                this.props.history.push('/app/users/' + newUserId + '/edit')
            })
        }

    }

    handleSavePermission(newPermissions) {
        const {match} = this.props;
        const userID = match.params.userID
        this.setState({permissionsLoaded: false}, () => {
            this.userApi.patchItem(
                userID + "/permission",
                {"permissions": newPermissions}
            ).then(this.processResponseData.bind(this))
        })
    }

    render() {
        let emailValidationText = <span>&nbsp;</span>
        if (this.state.currentData.email !== this.state.originalData.email) {
            if (!testEmail(this.state.currentData.email)) {
                emailValidationText = <span className="text-danger">Email address is invalid.</span>;
            } else {
                emailValidationText = <span className="text-success">Email address is valid.</span>;
            }
        }

        let usernameValidation = <span>&nbsp;</span>;

        if (this.state.currentData.username !== this.state.originalData.username) {
            if (this.state.usernameStatus) {
                usernameValidation = <span className="text-success">{this.state.usernameStatusMessage}</span>;
            } else {
                usernameValidation = <span className="text-danger">{this.state.usernameStatusMessage}&nbsp;</span>;
            }
        }


        const {match} = this.props;
        const userID = match.params.userID

        return <>
            <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h2>{this.state.originalData.username ? <span>
                                <span>Editing</span> <strong>{this.state.originalData.username}</strong>
                            </span> : "New User"}</h2>
                            </CardTitle>

                            <Form>

                                <Row form>
                                    <Colxx md={12}>
                                        <FormGroup>
                                            <Label for="username">Username</Label>
                                            <Input
                                                type="text"
                                                name="username"
                                                id="username"
                                                placeholder="username"
                                                value={this.state.currentData.username}
                                                onChange={this.handleUsernameChange.bind(this)}
                                            />
                                            {usernameValidation}
                                        </FormGroup>
                                    </Colxx>
                                </Row>
                                <Row form>
                                    <Colxx md={12}>
                                        <FormGroup>
                                            <Label for="email">Email Address</Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                id="email"
                                                placeholder="user@email.com"
                                                value={this.state.currentData.email}
                                                onChange={this.handleCurrentDataChange.bind(this, 'email')}
                                            />
                                            {emailValidationText}
                                        </FormGroup>
                                    </Colxx>
                                </Row>

                                <Row form>
                                    <Colxx md={12}>
                                        <FormGroup>
                                            <Label for="Description">Description</Label>
                                            <Input
                                                type="textarea"
                                                name="description"
                                                id="description"
                                                placeholder="User description (optional)"
                                                value={this.state.currentData.profile && this.state.currentData.profile.description}
                                                onChange={this.handleDescriptionChange.bind(this)}
                                            />
                                        </FormGroup>
                                    </Colxx>
                                </Row>

                                <Button color="primary" className="mt-2"
                                        disabled={!this.isDataChanged() || !this.isDataValid()}
                                        onClick={this.handleSave.bind(this)}>
                                    Save
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
            {
                userID && this.state.permissionsLoaded && this.state.originalData.additional_permission_display &&
                <UserPermissionForm
                    userID={userID}
                    permissionDisplay={this.state.originalData.additional_permission_display}
                    save={this.handleSavePermission.bind(this)}
                />
            }


            {
                userID && this.state.permissionsLoaded && <UserPromoPermissionForm
                    userID={userID}
                />
            }
        </>
    }
}

export default UserForm