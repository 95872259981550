import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {Button, Card, CardBody, CardTitle, Form, FormGroup, Input, Label, Row} from "reactstrap";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import {injectIntl} from "react-intl";
import ApiClient from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";


const DEFAULT_WEBHOOK_DATA = {
    'name': null,
    'uid': null,
}


class ApiHookPromoForm extends Component {
    state = {
        promoId: null,
        promos: [],
        entryAttribute: '',
        showNew: false
    }
    promoApi = new ApiClient('promo', this.authenticationErrorHandler.bind(this))
    hookApi = new ApiClient('hook', this.authenticationErrorHandler.bind(this))

    componentDidMount() {
        this.updatePromoList()
    }

    authenticationErrorHandler() {
        clearAuthTokens();
        this.props.history.replace('/login/')
    }

    updatePromoList() {
        this.promoApi.list().then(response => {
            this.setState({promos: response.data})
        })
    }

    handleChangePromo(option) {
        this.setState({promoId: option.value})
    }

    handleEntryAttributeChange(e) {
        this.setState({
            entryAttribute: e.target.value
        })
    }

    isDataChanged() {
        const selectedPromoId = this.state.promoId ? this.state.promoId : this.props.originalData && this.props.originalData.config.id;
        const entryAttribute = this.state.entryAttribute ? this.state.entryAttribute : this.props.originalData && this.props.originalData.config.ENTRY_COUNT_PARAM_NAME;
        if (this.props.originalData && this.props.originalData.id) {
            return (this.state.promoId && this.props.originalData.config.id !== this.state.promoId) || (this.state.entryAttribute && this.props.originalData.config.ENTRY_COUNT_PARAM_NAME !== this.state.entryAttribute)
        } else {
            return !!this.state.promoId;
        }
        return this.state.promoId !== selectedPromoId || this.state.entryAttribute !== entryAttribute;
    }

    handleSave() {
        const id = this.props.originalData && this.props.originalData.id;
        const selectedPromoId = this.state.promoId ? this.state.promoId : this.props.originalData && this.props.originalData.config.id;
        const entryAttribute = this.state.entryAttribute ? this.state.entryAttribute : this.props.originalData && this.props.originalData.config.ENTRY_COUNT_PARAM_NAME;
        const data = {
            "hook_type": "promo",
            "config": {
                "id": selectedPromoId,
                "ENTRY_COUNT_PARAM_NAME": entryAttribute
            }
        }
        if (id) {
            this.hookApi.patchItem(id, data).then(response => {
                this.props.handleHookSave(response.data)
            })
        } else {
            this.hookApi.create({...data, webhook: this.props.webhookUid}).then(response => {
                this.props.handleHookSave(response.data)
            })
        }


    }

    render() {
        if (!(this.props.originalData && this.props.originalData.id) && !this.state.showNew) {
            return <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <Button onClick={() => {
                                    this.setState({showNew: true})
                                }}>
                                    Enable Promo Hook
                                </Button>
                            </CardTitle>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
        }
        const promoOptions = this.state.promos.map( promo => {
            return {
                "value": promo.id,
                "label": promo.title,
                "key": "promo-" + promo.id
            }
        })
        const selectedPromoId = this.state.promoId ? this.state.promoId : this.props.originalData && this.props.originalData.config.id;
        const selectedPromoOption = selectedPromoId && promoOptions && promoOptions.filter(
            promoOption => promoOption.value === selectedPromoId
        )[0]

        const entryAttribute = this.state.entryAttribute ? this.state.entryAttribute : this.props.originalData && this.props.originalData.config.ENTRY_COUNT_PARAM_NAME;

        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h4>Promo Hook</h4>
                        </CardTitle>
                        <Form>
                            <Row form>
                                <Colxx md={12}>
                                    <FormGroup>
                                        <Label for="promoTitle">Promo</Label>

                                        <Select
                                            components={{ Input: CustomSelect }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="form-field-name"
                                            value={selectedPromoOption}
                                            placeholder="Select Promo"
                                            onChange={this.handleChangePromo.bind(this)}
                                            options={promoOptions}
                                        />
                                    </FormGroup>
                                </Colxx>
                            </Row>
                            <Row form>
                                <Colxx md={12}>
                                    <FormGroup>
                                        <Label for="promoTitle">Points Fetching Attribute</Label>
                                        <Input
                                            type="text"
                                            name="entryAttribute"
                                            id="entryAttribute"
                                            placeholder="entry_count"
                                            value={entryAttribute}
                                            onChange={this.handleEntryAttributeChange.bind(this)}
                                        />
                                    </FormGroup>
                                    <p className="text-small">
                                        This will be used to send entry count to mobile monkey. Default if not set is <code>entry_count</code>
                                    </p>
                                </Colxx>
                            </Row>

                            <Button color="primary" className="mt-2" disabled={!this.isDataChanged()} onClick={this.handleSave.bind(this)}>
                                Save
                            </Button>
                        </Form>
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }
}


export default class ApiHookForm extends Component {
    state = {
        ...DEFAULT_WEBHOOK_DATA,
        promos: [],
        originalData: {...DEFAULT_WEBHOOK_DATA}
    }

    webhookApi = new ApiClient('webhook', this.authenticationErrorHandler.bind(this))

    constructor(props) {
        super(props);
    }

    authenticationErrorHandler() {
        clearAuthTokens();
        this.props.history.replace('/login/')
    }

    componentDidMount() {
        const {match} = this.props;
        const webhookUid = match.params.webhookUid

        if (webhookUid) {
            this.webhookApi.getItem(webhookUid).then(this.processResponseData.bind(this))
        }

    }

    processResponseData(response) {

        const promoData = response.data
        this.setState({...promoData, originalData: {...promoData}})
    }

    isDataChanged() {
        return this.state.originalData.name !== this.state.name
    }

    handleNameChange(e) {
        this.setState({"name": e.target.value})
    }

    handleSave() {
        const newData = {name: this.state.name}

        const {match} = this.props;
        const webhookUid = match.params.webhookUid

        if (webhookUid) {
            this.webhookApi.updateItem(webhookUid, newData).then(this.processResponseData.bind(this))
        } else {
            this.webhookApi.create(newData).then(response => {
                const newPromoId = response.data.uid;
                this.props.history.push('/app/apiHooks/' + newPromoId + '/')
            })
        }
    }

    handleHookSave(hookData) {
        this.setState(prevState => {
            const isExistingHook = prevState.hooks.some(h => h.id === hookData.id)
            return {
                ...prevState,
                hooks: isExistingHook ? prevState.hooks.map(h => {
                    if (h.id === hookData.id) {
                        return {...h, ...hookData}
                    }
                    return h
                }) : [
                    ...prevState.hooks,
                    hookData
                ],
                originalData: {
                    ...prevState.originalData,
                    hooks: isExistingHook ? prevState.originalData.hooks.map(h => {
                        if (h.id === hookData.id) {
                            return {...h, ...hookData}
                        }
                        return h
                    }) : [
                        ...prevState.originalData.hooks,
                        hookData
                    ]
                }
            }
        })
    }

    render() {
        const promoHooks = this.state.hooks && this.state.hooks.filter(hook => hook.hook_type === 'promo')
        const promoHook = promoHooks && promoHooks[0];

        return <>
            <Row className="mb-4">
                <Colxx xxs="12">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h2>{this.state.originalData.name ? <span>
                                <span>Editing</span> <strong>{this.state.originalData.name}</strong>{" "}
                                    (<code>{this.state.originalData.uid}</code>)
                            </span> : "New Webhook"}</h2>
                            </CardTitle>
                            <Form>
                                <Row form>
                                    <Colxx md={12}>
                                        <FormGroup>
                                            <Label for="promoTitle">API Hook Name</Label>
                                            <Input
                                                type="text"
                                                name="webhookName"
                                                id="webhookName"
                                                placeholder="API Hook Name"
                                                value={this.state.name}
                                                onChange={this.handleNameChange.bind(this)}
                                            />
                                        </FormGroup>
                                    </Colxx>
                                </Row>

                                <Button color="primary" className="mt-2" disabled={!this.isDataChanged()} onClick={this.handleSave.bind(this)}>
                                    Save
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>

            { this.state.originalData.uid && <ApiHookPromoForm webhookUid={this.state.originalData.uid} originalData={promoHook} handleHookSave={this.handleHookSave.bind(this)}/> }
        </>

    }
}