import React, {Component, ReactFragment} from "react";
import IntlMessages from "../../../util/IntlMessages";
import {injectIntl} from "react-intl";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Media, Progress, Row, Table} from "reactstrap";
import TodoList from "../../../components/TodoList";
import {NavLink} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Separator} from "../../../components/CustomBootstrap";
import BreadcrumbContainer from "../../../components/BreadcrumbContainer";
import {BarShadow, LineShadow} from "../../../components/Charts";
import {barChartConfigSingle, lineChartConfig} from "../../../constants/chartConfig";
import TwoLevelPieChart from "../../../components/Rechart/TwoLevelPieChart";
import TinyLineChart from "../../../components/Rechart/TinyLine";
import ReactTable from "react-table-6";
import DataTablePagination from "../../../components/DataTables/pagination";
import Sortable from "react-sortablejs";
import RadarMultipleSeries from "../../../components/ApexCharts/RadarMultipleSeries";

import "chartjs-plugin-datalabels";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-table-6/react-table.css";
import Weather from "../../../components/Weather/Weather";
import WeatherSVG from "../../../components/Weather/WeatherSVG";

import eventsData from "../../../data/events.json";
import messagesData from "../../../data/messages.json";
import popularitemsData from "../../../data/popularitems.json";
import recentCustomers from "../../../data/recentCustomers.json";
import recentActivities from "../../../data/recentActivities.json";
import productsData from "../../../data/products.json";
import profileStatusData from "../../../data/profileStatus.json";
import {Sparklines, SparklinesBars, SparklinesLine, SparklinesSpots} from "react-sparklines";

// Chart.defaults.global.plugins.datalabels.display = false;  // FIXME
const sparklineData = [936, 968, 1025, 999, 998, 1014, 1017, 1010, 1010, 1007];
const sparklineWeek = [936, 968, 936, 930, 998, 1014, 1014, 1010, 900, 1007];
const sparklineMonth = [1000, 968, 900, 920, 950, 1014, 1017, 1010, 930, 1007];
const sparklineYear = [857, 870, 930, 999, 998, 930, 1017, 1070, 1030, 1007];
const sparklineBar = [5, 6, 7, 2, 0, -4, -2, 4];
const sparklineIncome = [1, 1, 0, 1, 2, 3, 1, 4, 2, 6, 1, 1];
function boxMullerRandom() {
  let phase = false,
    x1,
    x2,
    w,
    z;

  return (function() {
    if ((phase = !phase)) {
      do {
        x1 = 2.0 * Math.random() - 1.0;
        x2 = 2.0 * Math.random() - 1.0;
        w = x1 * x1 + x2 * x2;
      } while (w >= 1.0);

      w = Math.sqrt((-2.0 * Math.log(w)) / w);
      return x1 * w;
    } else {
      return x2 * w;
    }
  })();
}

function randomData(n = 30) {
  return Array.apply(0, Array(n)).map(boxMullerRandom);
}

const dataTableColumns = [
  {
    Header: "Invoice",
    accessor: "invoice",
    Cell: props => <p className="list-item-heading">{props.value}</p>
  },
  {
    Header: "User",
    accessor: "username",
    Cell: props => <p className="text-muted">{props.value}</p>
  },
  {
    Header: "Order Date",
    accessor: "orderDate",
    Cell: props => <p className="text-muted">{props.value}</p>
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: props => <p className="text-muted">{props.value}</p>
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: props => (
      <span className="badge badge-outline-theme-1 fontsize10px pr-1 pl-1 mt-2 w-100px">
        {props.value}
      </span>
    )
  },
  {
    Header: "Tracking Number",
    accessor: "trackingNumber",
    Cell: props => <p className="text-muted">{props.value}</p>
  }
];
const itemsDesc = productsData.data.slice(0, 6);
const messagesD = messagesData.data;
const popularitem = popularitemsData.data;
const recentCustomer = recentCustomers.data;
const recentAct = recentActivities.data;
const events = eventsData.data;
const dataTableData = productsData.data.slice(0, 12);
const profileStatuses = profileStatusData.data;

class DefaultDashboard extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);

    this.state = {
      selectedOptions: [],
      selectedOptionsType: []
    };

    this.state = { data: [] };
    setInterval(
      () =>
        this.setState({
          data: this.state.data.concat([boxMullerRandom()])
        }),
      100
    );
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleChangeType = selectedOptionsType => {
    this.setState({ selectedOptionsType });
  };

  render() {
    return <React.Fragment>
      <Row>
        <Col xxs="12">
          <BreadcrumbContainer
              heading={<IntlMessages id="menu.default" />}
              match={this.props.match}
          />
          <Separator className="mb-5" />
        </Col>
      </Row>
      {/* First Row starts here  */}
      <Row>
        <Col lg="3" sm="6" xs="12">
          <Card className="mb-4">
            <CardBody>
              <i className="float-left fa fa-cart-plus text-success mt-1 font18px" />
              <div className="ml-5">
                <p className="text-muted font-weight-semibold mb-2">
                  <IntlMessages id="main.weekly-sales" />
                </p>
                <p className="lead text-muted bold">1345</p>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="3" sm="6" xs="12">
          <Card className="mb-4">
            <CardBody>
              <i className="float-left fa fa-chart-line mt-1 font18px text-info" />
              <div className="ml-5">
                <p className="text-muted font-weight-semibold mb-2">
                  <IntlMessages id="main.monthly-visits" />
                </p>
                <p className="lead text-muted bold">687,123</p>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="3" sm="6" xs="12">
          <Card className="mb-4">
            <CardBody>
              <i className="float-left fa fa-shopping-bag mt-1 font18px text-danger" />
              <div className="ml-5">
                <p className="text-muted font-weight-semibold mb-2">
                  <IntlMessages id="main.items-for-sale" />
                </p>
                <p className="lead text-muted bold">1054</p>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="3" sm="6" xs="12">
          <Card className="mb-4">
            <CardBody>
              <i className="float-left fa fa-users mt-1 font18px text-warning" />
              <div className="ml-5">
                <p className="text-muted font-weight-semibold mb-2">
                  <IntlMessages id="main.users" />
                </p>
                <p className="lead text-muted bold">505,652</p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* Small Divs */}

      {/* Second Row starts here */}

      {/* Line Chart starts here */}
      <Row>
        <Col lg="8" md="12" sm="12" xs="12" className="mb-4">
          <Card>
            <CardBody>
              <CardTitle>
                <IntlMessages id="main.sales" />
              </CardTitle>
              <div className="dashboard-line-chart">
                <LineShadow {...lineChartConfig} />
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* Line Chart Ends Here */}

        <Col lg="4" xl="4" sm="12" className="mb-4">
          <Card>
            <div className="position-absolute card-top-buttons">
              <button className="btn btn-header-light icon-button">
                <i className="simple-icon-refresh" />
              </button>
            </div>
            <CardBody>
              <CardTitle>
                <IntlMessages id="main.statistics" />
              </CardTitle>
              <div className="clearfix">
                <div className="float-left font15px">
                  <IntlMessages id="main.traffic" />
                </div>
                <div className="float-right">
                  <sup className="text-success">+31</sup> <span>500K</span>
                  <sup>
                    <Sparklines data={this.state.data} limit={20}>
                      <SparklinesLine color="#922c88" />
                      <SparklinesSpots />
                    </Sparklines>
                  </sup>
                </div>
              </div>

              {/* Traffic Ends here */}

              <hr className="border" />

              {/* Conversion Starts here */}
              <div className="clearfix">
                <div className="float-left font15px">
                  <IntlMessages id="main.conversion" />
                </div>
                <div className="float-right">
                  <sup className="text-danger">-8</sup> <span>$150K</span>
                  <sup>
                    <Sparklines data={sparklineBar}>
                      <SparklinesBars
                          style={{
                            stroke: "white",
                            strokeWidth: "1",
                            fill: "#922c88"
                          }}
                      />
                    </Sparklines>
                  </sup>
                </div>
              </div>
              {/* Conversion Ends here */}
              <hr className="border" />
              {/* Bounce Rate starts here */}
              <div className="clearfix">
                <div className="float-left font15px">
                  <IntlMessages id="main.bounce-rate" />
                </div>
                <div className="float-right">
                  <sup className="text-success">+21%</sup> <span>5K</span>
                  <sup>
                    <Sparklines data={sparklineData} margin={6}>
                      <SparklinesLine
                          style={{
                            strokeWidth: 3,
                            stroke: "#922c88",
                            fill: "none"
                          }}
                      />
                    </Sparklines>
                  </sup>
                </div>
              </div>
              {/* Bounce Rate ends here */}
              <hr className="border" />
              {/* Income starts here */}
              <div className="clearfix">
                <div className="float-left font15px">
                  <IntlMessages id="main.income" />
                </div>
                <div className="float-right">
                  <sup className="text-danger">-23</sup> <span>430K</span>
                  <sup>
                    <Sparklines data={sparklineIncome} margin={6}>
                      <SparklinesLine
                          style={{
                            strokeWidth: 3,
                            stroke: "#922c88",
                            fill: "#922c88",
                            fillOpacity: "1"
                          }}
                      />
                      <SparklinesSpots
                          size={4}
                          style={{
                            stroke: "red",
                            strokeWidth: 3,
                            fill: "white"
                          }}
                      />
                    </Sparklines>
                  </sup>
                </div>
              </div>

              <hr className="border" />
              {/* Income ends here */}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* Second Row Ends Here  */}
      {/*  Table Starts Here */}
      <Row>
        <Col xl="12" lg="12" className="mb-4">
          <Card className="h-100">
            <CardBody>
              <CardTitle>
                <IntlMessages id="main.order-status" />
              </CardTitle>
              <Row className="mb-3">
                <Button color="primary" className="mb-2">
                  <i className="fa fa-plus-square-o" /> Add
                </Button>
                <Button color="primary" className="mb-2">
                  <i className="fa fa-print" />
                </Button>
                <Button color="primary" className="mb-2">
                  <i className="fa fa-file-pdf-o" /> Export PDF
                </Button>
                <div className="mb-2">
                  <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      placeholder="Search"
                      id="demo-input-search2"
                  />
                </div>
              </Row>
              <ReactTable
                  defaultPageSize={6}
                  data={dataTableData}
                  columns={dataTableColumns}
                  minRows={0}
                  PaginationComponent={DataTablePagination}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Table Ends Here */}
      <Row>
        <Col lg="4" md="12" className="mb-4">
          <Card>
            <CardBody>
              <CardTitle>
                <IntlMessages id="main.product-categories" />
              </CardTitle>
              <div className="dashboard-bar-chart">
                <BarShadow {...barChartConfigSingle} />
              </div>
              <CardTitle className="mt-4">
                <IntlMessages id="main.sales-goal" />
              </CardTitle>
              {profileStatuses.map((s, index) => {
                return (
                    <div key={index} className="mb-4">
                      <p className="mb-2">
                        {s.title}
                        <span className="float-right text-muted">
                          {s.status}/{s.total}
                        </span>
                      </p>
                      <Progress value={(s.status / s.total) * 100} />
                    </div>
                );
              })}
            </CardBody>
          </Card>
        </Col>

        {/* Todo List */}

        <Col lg="4" md="12" className="mb-4">
          <Card>
            <CardBody>
              <CardTitle>
                <IntlMessages id="main.tasks" />
              </CardTitle>
              <TodoList />
            </CardBody>
          </Card>
        </Col>

        {/* Messages */}
        <Col lg="4" md="12" className="mb-4">
          <Card>
            <CardBody>
              <CardTitle>
                <IntlMessages id="main.team-messages" />

                <div className="mt-2">
                    <span className=" bg-theme-1 mr-2 mb-2 pl-1 pr-1 fontsize10px">
                      <IntlMessages id="main.total-online" />
                    </span>
                  <span className="fontsize10px bg-danger text-white pl-1 pr-1">
                      <IntlMessages id="main.total-offline" />
                    </span>
                </div>
              </CardTitle>
              <div className="dashboard-recent-act-list">
                <PerfectScrollbar
                    option={{ suppressScrollX: true, wheelPropagation: false }}
                >
                  {messagesD.map((message, index) => {
                    return (
                        <div
                            key={index}
                            className="d-flex flex-row mb-3 pb-3 border-bottom"
                        >
                          <NavLink to="/app/layouts/details">
                            <img
                                src={message.thumb}
                                alt={message.label}
                                className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                            />

                            <p
                                className={`${
                                    message.color
                                } fontsize10px pr-1 pl-1 mt-2 text-white`}
                            >
                              {message.online}
                            </p>
                          </NavLink>

                          <div className="pl-3 pr-2">
                            <div className="float-right">{message.time}</div>
                            <NavLink to="/app/layouts/details">
                              <p className="font-weight-medium mb-0 ">
                                {message.label}
                              </p>

                              <p className="text-muted mb-0 mt-1 text-small">
                                {message.message}
                              </p>
                            </NavLink>
                          </div>
                        </div>
                    );
                  })}
                </PerfectScrollbar>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Recent Customers End here */}


      <Sortable
          options={{
            handle: ".handle"
          }}
          className="row"
      >
        <Col xl="3" lg="6" className="mb-4">
          <Card>
            <CardHeader className="p-0 position-relative">
              <div className="position-absolute handle card-icon">
                <i className="simple-icon-shuffle" />
              </div>
            </CardHeader>
            <CardBody className="d-flex justify-content-between align-items-center">
              <CardTitle className="mb-0 mt-2">
                <IntlMessages id="main.todays" />{" "}
                <span className="fa fa-level-down-alt text-danger" />
                <p>
                  {" "}
                  <span className="bg-danger bold-fonts  mb-0 fontsize10px pr-1 pl-1 text-white text-uppercase">
                      <IntlMessages id="main.sales-progress" />
                    </span>
                </p>
              </CardTitle>{" "}
              <div className="float-right mt-3">
                <sup className="text-danger float-right">-10</sup>{" "}
                <span>$150K</span>
                <sup>
                  <Sparklines data={sparklineData} margin={6}>
                    <SparklinesLine
                        style={{
                          strokeWidth: 3,
                          stroke: "#dc3545",
                          fill: "none"
                        }}
                    />
                  </Sparklines>
                </sup>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="3" lg="6" className="mb-4">
          <Card>
            <CardHeader className="p-0 position-relative">
              <div className="position-absolute handle card-icon">
                <i className="simple-icon-shuffle" />
              </div>
            </CardHeader>
            <CardBody className="d-flex justify-content-between align-items-center">
              <CardTitle className="mb-0 mt-2">
                <IntlMessages id="main.weekly" />
                <span className="fa fa-level-down-alt font-color-1" />
                <p>
                  {" "}
                  <span className="bg-primary bold-fonts  mb-0 fontsize10px pr-1 pl-1 text-white text-uppercase">
                      <IntlMessages id="main.sales-progress" />
                    </span>
                </p>
              </CardTitle>
              <div className="float-right mt-3">
                <sup className="font-color-1 float-right">+15</sup>{" "}
                <span>$963K</span>
                <sup>
                  <Sparklines data={sparklineWeek} margin={6}>
                    <SparklinesLine
                        style={{
                          strokeWidth: 3,
                          stroke: "#922c88",
                          fill: "none"
                        }}
                    />
                  </Sparklines>
                </sup>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="3" lg="6" className="mb-4">
          <Card>
            <CardHeader className="p-0 position-relative">
              <div className="position-absolute handle card-icon">
                <i className="simple-icon-shuffle" />
              </div>
            </CardHeader>
            <CardBody className="d-flex justify-content-between align-items-center">
              <CardTitle className="mb-0 mt-2">
                <IntlMessages id="main.monthly" />{" "}
                <span className="fa fa-level-up-alt text-danger" />
                <p>
                  {" "}
                  <span className="bg-danger bold-fonts  mb-0 fontsize10px pr-1 pl-1 text-white text-uppercase">
                      <IntlMessages id="main.sales-progress" />
                    </span>
                </p>
              </CardTitle>{" "}
              <div className="float-right mt-3">
                <sup className="float-right text-danger">-30</sup>{" "}
                <span>$3610K</span>
                <sup>
                  <Sparklines data={sparklineMonth} margin={6}>
                    <SparklinesLine
                        style={{
                          strokeWidth: 3,
                          stroke: "#dc3545",
                          fill: "none"
                        }}
                    />
                  </Sparklines>
                </sup>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="3" lg="6" className="mb-4">
          <Card>
            <CardHeader className="p-0 position-relative">
              <div className="position-absolute handle card-icon">
                <i className="simple-icon-shuffle" />
              </div>
            </CardHeader>
            <CardBody className="d-flex justify-content-between align-items-center">
              <CardTitle className="mb-0 mt-2">
                <IntlMessages id="main.yearly" />{" "}
                <span className="fa fa-level-up-alt font-color-1" />
                <p>
                  {" "}
                  <span className="bg-primary bold-fonts  mb-0 fontsize10px pr-1 pl-1 text-white text-uppercase">
                      <IntlMessages id="main.sales-progress" />
                    </span>
                </p>
              </CardTitle>
              <div className="float-right mt-3">
                <sup className="font-color-1 float-right">+7</sup>{" "}
                <span>$14908K</span>
                <sup>
                  <Sparklines data={sparklineYear} margin={6}>
                    <SparklinesLine
                        style={{
                          strokeWidth: 3,
                          stroke: "#922c88",
                          fill: "none"
                        }}
                    />
                  </Sparklines>
                </sup>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Sortable>


      <Row>
        <Col sm="12" md="12" lg="6" xl="4" className="mb-4">
          <Card>
            <CardBody>
              <div className="text-center float-none-xs">
                <div className="d-inline-block">
                  <h5 className="d-inline">
                    <IntlMessages id="main.weather-forecast" />
                  </h5>
                  <span className="text-muted text-small d-block">
                      <IntlMessages id="main.realtime-weather" />
                    </span>
                </div>
              </div>
              <Row className="mx-auto">
                <WeatherSVG />
              </Row>
            </CardBody>

            <div className="card-body pt-0">
              <Weather />
            </div>
          </Card>
        </Col>
        <Col lg="6" sm="12" md="12" xl="4" className="mb-4">
          <Card>
            <CardBody>
              <div className="pie-mini-minHeight">
                <div className="text-center">
                  <div className="d-inline-block ">
                    <h5 className="d-inline">
                      <IntlMessages id="main.last-year-income" />
                    </h5>
                    <span className="text-muted text-small d-block">
                        <IntlMessages id="main.last-year-sales-report" />
                      </span>
                  </div>
                </div>
                <div className="py-3 mx-auto">
                  <Row>
                    <Col lg="2" md="4" sm="4" xs="3" />
                    <Col className="mt-3" lg="8" md="mx-auto" sm="6" xs="9">
                      <TwoLevelPieChart />
                    </Col>
                    <Col lg="2" md="3" sm="2" />
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* Tiny Line Chart Starts here */}

        <Col lg="6" sm="12" md="12" xl="4" className="mb-4">
          <Card>
            <CardBody>
              <div className="pie-mini-minHeight">
                <div className="text-center">
                  <div className="d-inline-block ">
                    <h5 className="d-inline">
                      <IntlMessages id="main.last-year-income" />
                    </h5>
                    <span className="text-muted text-small d-block">
                        <IntlMessages id="main.last-year-sales-report" />
                      </span>
                  </div>
                </div>
                <div className="py-3 mx-auto">
                  <Row>
                    <Col lg="2" md="4" sm="4" xs="3" />
                    <Col className="mt-5" lg="8" md="mx-auto" sm="6" xs="9">
                      <TinyLineChart />
                    </Col>
                    <Col lg="2" md="3" sm="2" />
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* Tiny Line Chart Ends Here */}
      </Row>

      {/* Transactions Table Starts here */}

      <Row>
        <Col sm="12" lg="6" className="mb-4">
          <Card className="h-100">
            <CardBody>
              <CardTitle>
                <IntlMessages id="main.popular-items" />
              </CardTitle>
              <div className="dashboard-products-list">
                <PerfectScrollbar
                    option={{ suppressScrollX: true, wheelPropagation: false }}
                >
                  {popularitem.map((popular, index) => {
                    return (
                        <div
                            key={index}
                            className="d-flex flex-row mb-3 pb-3 border-bottom"
                        >
                          <Media>
                            <Media left top href="#">
                              <Media
                                  className="img120px px-2 py-2"
                                  object
                                  src={popular.preview}
                                  alt={popular.alt}
                              />
                            </Media>
                            <Media className="px-2 py-2" body>
                              <Media className="font-weight-medium">
                                {popular.label}
                              </Media>
                              <p className="text-muted mb-2 mt-1 text-small">
                                {popular.description}
                              </p>
                              <del>{popular.oldprice}</del>{" "}
                              <span>{popular.newprice}</span>
                              <div className="mt-2">
                                <span className={`${popular.stars}`} />
                                <span className={`${popular.stars}`} />
                                <span className={`${popular.stars}`} />
                                <span className={`${popular.starshalf}`} />
                                <span className={`${popular.starsempty}`} />
                              </div>
                            </Media>
                          </Media>
                        </div>
                    );
                  })}
                </PerfectScrollbar>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* Transctions Table Ends Here */}

        {/* Recent Activities start here */}
        <Col lg="6" md="12" className="mb-4">
          <Card>
            <CardBody>
              <CardTitle>
                <IntlMessages id="main.recent-activities" />
              </CardTitle>
              <div className="dashboard-list-with-user">
                <PerfectScrollbar
                    option={{ suppressScrollX: true, wheelPropagation: false }}
                >
                  {recentAct.map((recentAct, index) => {
                    return (
                        <div
                            key={index}
                            className="d-flex flex-row mb-3 pb-3 border-bottom"
                        >
                          <NavLink to="/app/layouts/details">
                            <i className={`${recentAct.icons}`} />
                          </NavLink>

                          <div className="pl-3 pr-2">
                            <div className="float-right">{recentAct.time}</div>
                            <NavLink to="/app/layouts/details">
                              <p className="font-weight-medium mb-0 w-350">
                                {recentAct.label}
                              </p>

                              <p className="text-muted mb-0 mt-1 text-small">
                                {recentAct.subtitle}
                              </p>

                              <Progress
                                  className="mt-3"
                                  value={
                                    (recentAct.status / recentAct.total) * 100
                                  }
                              />
                              <p>
                                <span className="float-right text-muted">
                                  {recentAct.reached}
                                </span>
                              </p>
                            </NavLink>
                          </div>
                        </div>
                    );
                  })}
                </PerfectScrollbar>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* Recent Activties End Here */}
      </Row>

      {/*  Recent Customers */}
      <Row>
        <Col xl="6" lg="12" md="12" className="mb-4">
          <Card className="h-100">
            <CardBody>
              <CardTitle>
                <IntlMessages id="main.customers-status" />
              </CardTitle>
              <div className="dashboard-products-list">
                <PerfectScrollbar
                    option={{ suppressScrollX: true, wheelPropagation: false }}
                >
                  {recentCustomer.map((recent, index) => {
                    return (
                        <div
                            key={index}
                            className="d-flex flex-row border-bottom"
                        >
                          <Table striped>
                            <tbody>
                            <tr>
                              <td className="border-right">
                                <img
                                    src={recent.preview}
                                    alt={recent.alt}
                                    className="border-0 rounded-circle align-self-center w50px"
                                />
                                <p className="text-muted mt-2">
                                  {recent.time}
                                </p>
                              </td>
                              <td>
                                {recent.label}{" "}
                                <p className="text-muted mt-2">
                                  <span className="fa fa-map-marker-alt" />{" "}
                                  {recent.location}
                                </p>
                                <span
                                    className={`${
                                        recent.colors
                                    }  fontsize10px pr-1 pl-1 text-white text-uppercase`}
                                >
                                    {recent.customerState}
                                  </span>
                              </td>
                              <td className="text-muted w-100px" />
                            </tr>
                            </tbody>
                          </Table>
                        </div>
                    );
                  })}
                </PerfectScrollbar>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* Radar */}
        <Col xl="6" lg="12" md="12">
          <Card>
            <CardBody>
              <RadarMultipleSeries />
            </CardBody>
          </Card>
        </Col>

        {/* Radar Ends Here */}
      </Row>
    </React.Fragment>
  }
}
export default DefaultDashboard;
