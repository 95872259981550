import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle,
    CustomInput,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table
} from "reactstrap";
import ApiClient from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";
import "../../assets/css/bootstrap.extra.css";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import EntryDetail from "./entryDetail";


class EntryList extends Component {
    entryApi = new ApiClient('entry', this.authenticationErrorHandler.bind(this))
    promoApi = new ApiClient('promo', this.authenticationErrorHandler.bind(this))

    state = {
        entries: [],
        limit: 20,
        page: 1,
        hasPrevious: false,
        hasNext: false,
        totalCount: 0,
        filters: {
            // has_attachment_only: false,
            include_invalid: true
        },
        filterOptions: {},

        promoTitle: '',

        sort: '',

        modalEntry: null
    }

    constructor(props) {
        super(props);

        this.history = props.history;
    }

    fetchPromoName(promoID) {
        if (promoID) {
            this.promoApi.getItem(promoID).then(response => {
                this.setState({promoTitle: response.data.title})
            })
        } else {
            this.setState({promoTitle: ""})
        }
    }

    fetchEntries() {
        const params = {
            limit: this.state.limit,
            offset: (this.state.page - 1) * this.state.limit,
            ...this.state.filters
        }

        const { match } = this.props;
        const promoID = match.params.promoID

        if (promoID) {
            params["promo_id"] = promoID
        }
        if (this.state.sort) {
            params['sort'] = this.state.sort
        }

        this.fetchPromoName(promoID)

        this.entryApi.list({params: params}).then(response => {
            this.setState({
                hasNext: !!response.data.next,
                hasPrevious: !!response.data.previous,
                entries: response.data.results,
                totalCount: response.data.count
            })
        })
    }

    fetchFilters() {
        const { match } = this.props;
        const promoID = match.params.promoID

        const params = {}
        if (promoID) {
            params["promo_id"] = promoID
        }

        this.entryApi.getItem('filters', {params: params}).then(response => {
            this.setState({filterOptions: response.data})
        })
    }

    componentDidMount() {
        this.fetchEntries()
        this.fetchFilters()
    }

    authenticationErrorHandler() {
        clearAuthTokens();
        this.history.replace('/login/')
    }

    goToPage(pageNumber) {
        this.setState({page: pageNumber}, () => {
            this.fetchEntries()
        })
    }

    renderPagination() {
        const pageCount = Math.ceil(this.state.totalCount / this.state.limit);
        const visiblePages = [];

        let startPageCounting = this.state.page - 2 > 0 ? this.state.page - 2 : 1;
        let endPageCounting = startPageCounting + 5 <= pageCount ? startPageCounting + 5 : pageCount ;
        if (endPageCounting - startPageCounting < 5) {
            startPageCounting = endPageCounting - 5 > 0 ? endPageCounting - 5 : 1
        }

        while (startPageCounting <= endPageCounting) {
            visiblePages.push(startPageCounting)
            startPageCounting ++;
        }

        return <Pagination size="sm" aria-label="Page navigation example" className="justify-content-center">
            <PaginationItem disabled={!this.state.hasPrevious} onClick={() => {this.goToPage(1)}}>
                <PaginationLink
                    className="fa fa-angle-double-left"
                />
            </PaginationItem>
            <PaginationItem disabled={!this.state.hasPrevious}  onClick={() => {this.goToPage(this.state.page - 1)}}>
                <PaginationLink className="fa fa-angle-left" />
            </PaginationItem>
            {
                visiblePages.map(
                    p => <PaginationItem
                        key={"page-"+p} active={p === this.state.page}
                        onClick={() => {p !== this.state.page && this.goToPage(p)}}
                    >
                        <PaginationLink>{p}</PaginationLink>
                    </PaginationItem>
                )
            }
            <PaginationItem  disabled={!this.state.hasNext}  onClick={() => {
                this.goToPage(this.state.page + 1)
            }}>
                <PaginationLink
                    className="fa fa-angle-right"
                />
            </PaginationItem>
            <PaginationItem  disabled={!this.state.hasNext} onClick={() => {
                this.goToPage(pageCount)
            }}>
                <PaginationLink
                    className="fa fa-angle-double-right"
                />
            </PaginationItem>
        </Pagination>
    }

    handleChangeFilterValue(fullFilterKey, filterOption) {
        this.setState(prevState => {
            return {
                ...prevState,
                filters: {
                    ...prevState.filters,
                    [fullFilterKey]: filterOption ? filterOption.value : undefined
                }
            }
        })
    }

    toggleSort(fieldName) {
        this.setState(prevState => {
            let sortValue = ''
            if (prevState.sort === fieldName) {
                sortValue = '-' + fieldName
            } else if (prevState.sort === '-' + fieldName) {
                sortValue = ''
            } else {
                sortValue = fieldName
            }
            return {
                ...prevState,
                sort: sortValue
            }
        }, () => {
            this.fetchEntries()
        })
    }

    toggleModalEntry() {
        this.setState({modalEntry: null})
    }

    renderFilter(filterDefinition) {
        const filterKey = filterDefinition.key
        const fullFilterKey = (filterDefinition.is_raw ? 'raw_data__' : '') + filterKey

        const selectedOptionValue = this.state.filters[fullFilterKey]
        const selectedOption = selectedOptionValue ? filterDefinition.values.filter(options => options.value === selectedOptionValue)[0] : null
        const displayKey = filterKey.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());  // titleCase

        // TODO: add download entries
        return <Colxx key={"filter-" + filterKey}>
            <Label>{displayKey}</Label>
            <Select
                isClearable={true}
                components={{ Input: CustomSelect }}
                className="react-select small"
                classNamePrefix="react-select"
                name={"filter-" + filterKey}
                value={selectedOption}
                placeholder={"Select " + displayKey}
                onChange={this.handleChangeFilterValue.bind(this, fullFilterKey)}
                options={filterDefinition.values}
            />
        </Colxx>
    }

    renderFilters() {
        if (Object.keys(this.state.filterOptions).length <= 0) {
            return <i>No filters available...</i>
        }
        return <Row className="mb-4 border-1px py-3">
            <Colxx>
                <Row>
                    <Colxx>
                        <h4>
                            Filters
                        </h4>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx>
                        <CustomInput
                            type="checkbox"
                            id="CustomInlineCheckbox"
                            label={"Include invalid entries"}
                            checked={this.state.filters.include_invalid}
                            onChange={() => this.setState(
                                prevState => ({
                                    ...prevState,
                                    filters: {
                                        ...prevState.filters,
                                        include_invalid: !prevState.filters.include_invalid
                                    }
                                })
                            )}
                            inline
                        />
                        <select onChange={e => this.setState({limit: e.target.value})}>
                            <option selected={this.state.limit === 10}>10</option>
                            <option selected={this.state.limit === 20}>20</option>
                            <option selected={this.state.limit === 50}>50</option>
                            <option selected={this.state.limit === 100}>100</option>
                        </select> entries per page
                        {/*<CustomInput*/}
                        {/*    type="checkbox"*/}
                        {/*    id="CustomInlineCheckboxx"*/}
                        {/*    label="Second"*/}
                        {/*    inline*/}
                        {/*/>*/}
                    </Colxx>
                </Row>
                <Row className="align-items-end d-flex">
                    {
                        this.state.filterOptions.map( filterOptionDefinition => this.renderFilter(filterOptionDefinition))
                    }
                    <Colxx md={1} className="mb-1">
                        <Button size="sm" onClick={() => {this.fetchEntries()}}>
                            <span className="fa fa-search"/>
                        </Button>
                    </Colxx>
                </Row>
            </Colxx>
        </Row>
    }

    renderModalContent() {
        if (!this.state.modalEntry) {
            return <>Loading</>
        }
        return <>

            <ModalHeader toggle={this.toggleModalEntry.bind(this)}>
                Entry ID {this.state.modalEntry.id}
            </ModalHeader>
            <ModalBody>
                <EntryDetail entry={this.state.modalEntry}/>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.toggleLong}>
                    Do Something
                </Button>{" "}
                <Button color="secondary" onClick={this.toggleLong}>
                    Cancel
                </Button>
            </ModalFooter>
        </>
    }

    render() {
        const COL_TO_PROPERTY = {
            // 'ID': 'id',
            'Name': 'name',
            'Email Address': 'email_address',
            'Mobile Number': 'mobile_number',
            // 'Profile ID': 'profile_identifier',
            'Code': 'raw_code',
            'Entry Date': 'datetime_registered'
        }
        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h2>{this.state.promoTitle} Entries</h2>
                        </CardTitle>

                        { this.renderFilters() }

                        <Row>
                            <Colxx xxs="12" className="text-center">
                                {this.renderPagination()}
                            </Colxx>
                        </Row>

                        <Table hover striped>
                            <thead>
                            <tr>
                                <th></th>
                                {Object.keys(COL_TO_PROPERTY).map(
                                    k => <th key={"header-" + k} onClick={this.toggleSort.bind(this, COL_TO_PROPERTY[k])}>
                                        {k}{" "}
                                        { COL_TO_PROPERTY[k] === this.state.sort ? <span className="fa fa-caret-up"/> : '' }
                                        { "-" + COL_TO_PROPERTY[k] === this.state.sort ? <span className="fa fa-caret-down"/> : '' }
                                    </th>
                                )}
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.entries.map(entry => {
                                    return <tr
                                        key={"entry-" + entry.id}
                                        className={entry.is_active ? '' : 'text-danger font-italic'}
                                        style={{cursor: entry.is_active ? '' : 'not-allowed'}}
                                        title={!entry.is_active && (entry.entry_errors ? entry.entry_errors : "Invalid entry.")}
                                    >
                                        <td>
                                            {entry.id} {!entry.is_active && <><br/>invalid</>}
                                        </td>
                                        {Object.values(COL_TO_PROPERTY).map(k => <td key={"entry-" + entry.id + "-" + k}>{entry[k]}</td>)}
                                        <td>
                                            <ButtonGroup>
                                                <Button size="xs" outline onClick={
                                                    () => {
                                                        this.setState({
                                                            modalEntry: JSON.parse(JSON.stringify(entry))
                                                        })
                                                    }
                                                } disabled={entry.attachments.length <= 0}>
                                                    <span className="fa fa-image"/><br/>
                                                    img
                                                </Button>
                                                <Button size="xs" outline onClick={
                                                    () => {
                                                        this.setState({
                                                            modalEntry: JSON.parse(JSON.stringify(entry))
                                                        })
                                                    }
                                                }>
                                                    <span className="fa fa-eye"/><br/>
                                                    view
                                                </Button>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </Table>

                        {this.renderPagination()}

                        <Modal isOpen={!!this.state.modalEntry} toggle={this.toggleModalEntry.bind(this)}>
                            {this.renderModalContent()}
                        </Modal>
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }
}

export default EntryList