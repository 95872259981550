import React, { Component } from "react";
import { injectIntl } from "react-intl";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
  NavbarBrand,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import IntlMessages from "../../util/IntlMessages";

import PerfectScrollbar from "react-perfect-scrollbar";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  setContainerClassnames,
  clickOnMobileMenu,
  // logoutUser  // FIXME: logoutUser
} from "../../redux/actions";

import { menuHiddenBreakpoint, searchPath } from "../../constants/defaultValues";

import notifications from "../../data/topbar.notifications.json";
import getMessages from "../../data/topbar.messages.json";

// const p4 = require('../../assets/img/p4.png');
import p4 from '../../assets/img/p4.png'
import {clearAuthTokens} from "axios-jwt";
import Gallery from "react-fine-uploader";
import FineUploaderTraditional from "fine-uploader-wrappers";
import ApiClient from "../../api/ApiClient";
import ChangePassword from "../../routes/extras/changePassword";


const uploader = new FineUploaderTraditional({
  options: {
    chunking: {
      enabled: false
    },
    deleteFile: {
      enabled: false,
      endpoint: "/uploads"
    },
    request: {
      endpoint: "/uploads"
    }
  }
});


class TopNav extends Component {
  constructor(props) {
    super(props);
    this.menuButtonClick = this.menuButtonClick.bind(this);
    this.mobileMenuButtonClick = this.mobileMenuButtonClick.bind(this);
    this.state = {
      isInFullScreen: false,
      profilePhotoModal: false,

      profileName: 'Loading...',
      profilePhoto: undefined,
      profileId: undefined,

      canSaveProfilePhoto: false,
      tmpProfilePhotoFile: undefined,

      changePasswordModal: false
    };

    this.history = props.history;
    this.meApi = new ApiClient('me')

    this.tmpProfilePhotoFileInput = React.createRef()
  }

  componentDidMount() {
    this.meApi.list().then(response => {
      const profileId = response.data.my_profile_id;
      this.setState({profileId: profileId})
      this.meApi.getItem(profileId).then(response => {
        this.setState({
          profileName: response.data.name,
          profilePhoto: response.data.profile_photo,
          profileUpdateUrl: response.data.profile_photo_update_url
        })
      })
    }).catch(error => {
      if (error.response) {
        if (error.response.status == 403) {
          this.handleLogout()
        }
      }
    })
  }

  handleLogout = () => {
    clearAuthTokens();
    this.history.replace('/login/')
  };

  menuButtonClick(e, menuClickCount, containerClassnames) {
    e.preventDefault();

    setTimeout(() => {
      var event = document.createEvent("HTMLEvents");
      event.initEvent("resize", false, false);
      window.dispatchEvent(event);
    }, 350);
    this.props.setContainerClassnames(++menuClickCount, containerClassnames);
  }
  mobileMenuButtonClick(e, containerClassnames) {
    e.preventDefault();
    this.props.clickOnMobileMenu(containerClassnames);
  }

  toggleProfilePhotoModal() {
    this.setState(prevState => ({...prevState, profilePhotoModal: !prevState.profilePhotoModal}))
  }

  toggleChangePasswordModal() {
    this.setState(prevState => ({...prevState, changePasswordModal: !prevState.changePasswordModal}))
  }

  passwordChanged() {
    this.toggleChangePasswordModal()
  }

  handleProfilePhotoFileChange(e) {
    var formData = new FormData();
    formData.append("photo", this.state.tmpProfilePhotoFile);
    this.meApi.put(this.state.profileUpdateUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      this.setState({"profilePhoto": response.data.photo, tmpProfilePhotoFile: undefined}, () => {
        this.toggleProfilePhotoModal()
      })
    })
  }

  updateTmpProfilePhoto() {
    const selectedImage = this.tmpProfilePhotoFileInput.current.files[0];
    this.setState({tmpProfilePhotoFile: selectedImage})
  }

  render() {
    const { containerClassnames, menuClickCount } = this.props;
    const { messages } = this.props.intl;

    const profilePhotoSrc = this.state.profilePhoto ? this.state.profilePhoto : p4;
    const changeProfilePhotoSrc = this.state.tmpProfilePhotoFile ? URL.createObjectURL(this.state.tmpProfilePhotoFile) : profilePhotoSrc;
    return (
      <React.Fragment>
        <nav className="navbar fixed-top">
          <NavbarBrand className="navbar-brand navbar-logo ml-3" href="/">
            <span className="logo d-none d-xs-block" />
            <span className="logo-mobile d-block d-xs-none" />
          </NavbarBrand>
          <NavLink
            to="#"
            className="menu-button d-none d-md-block"
            onClick={e =>
              this.menuButtonClick(e, menuClickCount, containerClassnames)
            }
          >
            <span className="fa fa-stream" />
          </NavLink>
          <NavLink
            to="#"
            className="menu-button-mobile d-xs-block d-sm-block d-md-none"
            onClick={e => this.mobileMenuButtonClick(e, containerClassnames)}
          >
            <span className="fa fa-stream" />
          </NavLink>{" "}

          <div className="ml-auto">
            <div className="user d-inline-block">
              <UncontrolledDropdown className="dropdown-menu-right">
                <DropdownToggle className="p-0" color="empty">
                  <span className="name mr-1">{this.state.profileName}</span>
                  <span>
                    <img alt="Profile Picture" src={profilePhotoSrc} />
                  </span>
                </DropdownToggle>
                <DropdownMenu className="mt-3" right>
                  <DropdownItem>
                    <div className="float-left mr-3">
                      <img
                          className="Profile Picture"
                          src={profilePhotoSrc}
                          onClick={this.toggleProfilePhotoModal.bind(this)}/>{" "}
                    </div>
                    <div>
                      <strong>{this.state.profileName}</strong>
                      <p>Administrator</p>
                    </div>
                  </DropdownItem>
                  <DropdownItem onClick={this.toggleChangePasswordModal.bind(this)} >
                    <span className="fa fa-key font-color-1 mr-1"/> Change Password
                  </DropdownItem>

                  {/*<DropdownItem>*/}
                  {/*  <span className="fa fa-user font-color-1 mr-1" /> Profile*/}
                  {/*</DropdownItem>*/}
                  {/*<DropdownItem>*/}
                  {/*  <span className="fa fa-cog font-color-1 mr-1" /> Settings*/}
                  {/*</DropdownItem>*/}
                  {/*<DropdownItem>*/}
                  {/*  <span className="fa fa-edit font-color-1 mr-1" /> Tasks*/}
                  {/*</DropdownItem>*/}
                  <DropdownItem divider />
                  <DropdownItem onClick={() => this.handleLogout()}>
                    <span className="fa fa-power-off font-color-1 mr-1" /> Sign out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </nav>

        <Modal isOpen={this.state.profilePhotoModal} toggle={this.toggleProfilePhotoModal.bind(this)}>
          <ModalHeader toggle={this.toggleProfilePhotoModal.bind(this)}>
            Update Profile Photo
          </ModalHeader>
          <ModalBody className="text-center">

            <img
                className="Profile Picture"
                src={changeProfilePhotoSrc} style={{maxHeight: 250, maxWidth: 250}}/>

            <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                ref={this.tmpProfilePhotoFileInput}
                onChange={() => {
                  this.updateTmpProfilePhoto()
                }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
                color="primary"
                onClick={this.handleProfilePhotoFileChange.bind(this)}
                disabled={!this.state.tmpProfilePhotoFile}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleProfilePhotoModal.bind(this)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.changePasswordModal} toggle={this.toggleChangePasswordModal.bind(this)}>
          <ModalHeader toggle={this.toggleChangePasswordModal.bind(this)}>
            Change Password
          </ModalHeader>
          <ModalBody>
            <ChangePassword passwordChanged={this.passwordChanged.bind(this)} cancel={this.toggleChangePasswordModal.bind(this)}/>
          </ModalBody>
        </Modal>

      </React.Fragment>

    );
  }
}

const mapStateToProps = ({ menu }) => {
  const { containerClassnames, menuClickCount } = menu;
  return { containerClassnames, menuClickCount };
};
export default injectIntl(
  connect(
    mapStateToProps,
    {
      setContainerClassnames,
      clickOnMobileMenu,
      // logoutUser  // FIXME
    }
  )(TopNav)
);
