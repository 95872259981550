import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody, CardImg,
    CardTitle, CustomInput, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table
} from "reactstrap";
import ApiClient from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";
import PromoList from "./promoList";
import "../../assets/css/bootstrap.extra.css";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import Switch from "rc-switch";


class PromoDraw extends Component {
    state = {
        promoTitle: ""
    }
    render() {
        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h2>{this.state.promoTitle} Draw Winners</h2>
                        </CardTitle>

                        <Row>
                            <Colxx>
                                Draw random entries from
                                <select>
                                    <option>all time</option>
                                    <option>custom date range</option>
                                </select>
                                (select from) to (select to).
                                <br/>
                                Winning profiles
                                <select>
                                    <option>should have never won before</option>
                                    <option>can only win ONCE in this draw (may or may not have won before in previous draws)</option>
                                    <option>can win multiple times even in this draw</option>
                                </select>.

                                A unique profile is identified by (mobile number|email address|chatbot profile identifier).

                            </Colxx>
                        </Row>
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }
}

export default PromoDraw