import React from "react";

export default props => (
  <span className="d-block py-3 border-bottom">
    <span
      className="font12px"
      style={{
        textDecoration: props.todo.complete ? "line-through" : ""
      }}
      onClick={props.toggleComplete}
    >
      {props.todo.text}
    </span>
    <span
      className="fas fa-window-close font-color pl-2 font12px cursorp"
      onClick={props.onDelete}
    />
  </span>
);
