import {Row} from "reactstrap";
import {Colxx} from "../components/CustomBootstrap";
import React from "react";

export function keyValueToRow(key, value) {
    const displayKey = key.replace('_', ' ')
        .toLowerCase()
        .replace(/\b(\w)/g, s => s.toUpperCase());
    return <Row key={"row-" + key}>
        <Colxx md={4}>{displayKey}</Colxx>
        <Colxx md={8}>{value}</Colxx>
    </Row>
}

export function dictToRows(dict, ignoreKeys) {
    if (!ignoreKeys) {
        ignoreKeys = []
    }
    return Object.keys(dict).filter(
        k => ignoreKeys.indexOf(k) === -1 && typeof (dict[k]) === 'string' && !!dict[k]
    ).map(
        k => {
            return keyValueToRow(k, dict[k])
        }
    )
}