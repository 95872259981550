import React, { Component } from "react";
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Sector,
  Label,
  LabelList
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";

const colors = scaleOrdinal(schemeCategory10).range();

const data01 = [
  { value: 200, v: 89 },
  { value: 400, v: 89 },
  { value: 100, v: 89 },
  { value: 300, v: 89 },
  { value: 100, v: 89 },
  { value: 250, v: 89 },
  { value: 70, v: 89 },
  { value: 400, v: 89 },
  { value: 300, v: 89 },
  { value: 200, v: 89 },
  { value: 400, v: 89 }
];

const initialState = { data01 };

export default class TwoLevelPieChart extends Component {
  onPieEnter = (data, index, e) => {
    this.setState({
      activeIndex: index
    });
  };

  state = {
    ...initialState,
    activeIndex: 0
  };

  handleEnter = (e, activeIndex) => this.setState({ activeIndex });
  handleLeave = () => this.setState({ activeIndex: -1 });

  render() {
    const { data01 } = this.state;

    return (
      <div>
        {" "}
        <PieChart width={270} height={170} className="chartposition">
          <Pie
            data={data01}
            dataKey="value"
            startAngle={300}
            endAngle={0}
            outerRadius={80}
            innerRadius={60}
            outerRadius={80}
            label
            position="center"
          >
            {data01.map((entry, index) => (
              <Cell key={`slice-${index}`} fill="#922c88" />
            ))}
          </Pie>
        </PieChart>
      </div>
    );
  }
}
