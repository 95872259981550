import React from "react";
import { Form, Button, Input } from "reactstrap";
import IntlMessages from "../../util/IntlMessages";

class WeatherForm extends React.Component {
  render() {
    return (
      <div>
        <Form onSubmit={this.props.getWeatherUpdate}>
          <IntlMessages id="main.city-name">
            {cityName => (
              <Input
                className="mr-2 mb-2 mx-auto"
                type="text"
                name="city"
                placeholder={cityName}
              />
            )}
          </IntlMessages>
          <IntlMessages id="main.country-name">
            {countryName => (
              <Input
                type="text"
                name="country"
                placeholder={countryName}
                className="mr-2 mb-2 mx-auto"
              />
            )}
          </IntlMessages>
          <Button className="bg-theme-1 mx-auto">
            {" "}
            <IntlMessages id="main.get-weather-update" />
          </Button>
        </Form>
      </div>
    );
  }
}
export default WeatherForm;
