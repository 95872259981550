import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Label,
    Row
} from "reactstrap";
import ApiClient from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PointsFetch from "./mobileMonkeyPreview/pointsFetch";

export default class ApiHookList extends Component {
    state = {
        apiHooks: []
    }

    apiHooksApi = new ApiClient('webhook', this.authenticationErrorHandler.bind(this))

    constructor(props) {
        super(props);

        this.history = props.history;
    }

    authenticationErrorHandler() {
        clearAuthTokens();
        this.history.replace('/login/')
    }

    componentDidMount() {
        this.apiHooksApi.list().then(response => {
            const responseData = response.data
            this.setState({
                apiHooks: [...responseData]
            })
        })

    }

    render() {
        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <Button size="sm" className="float-right"  onClick={() => {
                                this.history.push('/app/apiHooks/new/')
                            }}>
                                <span className="fa fa-plus"/> New API Hook
                            </Button>
                            <h2>API Hooks</h2>
                        </CardTitle>
                        <ToastContainer />

                        {
                            this.state.apiHooks.map(apiHook => <>
                                <Row key={"apiHook" + apiHook.uid}>

                                    <Colxx className="p-0 text-center my-auto" md={1}>
                                        <ButtonGroup size="xs">
                                            <Button color="primary" onClick={() => {
                                                this.history.push('/app/apiHooks/' + apiHook.uid + '/')
                                            }}>
                                                <span className="fa fa-pen"/><br/>
                                                edit
                                            </Button>
                                        </ButtonGroup>
                                    </Colxx>
                                    <Colxx className="my-auto">
                                        <strong>{apiHook.name}</strong>
                                        {
                                            apiHook.points_fetch &&
                                            <p>* All Promo URLs require a mapping of <pre>
                                                <code>recipient_id</code> &lt;- <code>recipient id</code><br/>
                                            </pre></p>
                                        }
                                    </Colxx>
                                    <Colxx className="my-auto">

                                        <Label>Receiver URL</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText className="text-small">POST</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type="text" readOnly={true} value={apiHook.receive_url}
                                                size="lg" style={{textOverflow: "ellipsis"}}/>
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    color="success" size="xs"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(apiHook.receive_url);
                                                        toast("Receiver URL copied to clipboard")
                                                    }}>
                                                    <span className="fa fa-clipboard"/> copy
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        { apiHook.points_fetch && <>
                                            <br/>
                                            <Label>Points Fetch URL - saving to <code>{apiHook.points_fetch.key}</code> attribute</Label>
                                            <InputGroup>
                                                <InputGroupText className="text-small">GET</InputGroupText>
                                                <Input
                                                    type="text" readOnly={true} value={apiHook.points_fetch.url}
                                                    size="lg" style={{textOverflow: "ellipsis"}}/>
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        color="success" size="xs"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(apiHook.points_fetch.url);
                                                            toast("Points Fetching URL copied to clipboard")
                                                        }}>
                                                        <span className="fa fa-clipboard"/> copy
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </>}


                                        { apiHook.dispute && <>
                                            <br/>
                                            <Label>Dispute URL</Label>
                                            <InputGroup>
                                                <InputGroupText className="text-small">POST</InputGroupText>
                                                <Input
                                                    type="text" readOnly={true} value={apiHook.dispute.url}
                                                    size="lg" style={{textOverflow: "ellipsis"}}/>
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        color="success" size="xs"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(apiHook.dispute.url);
                                                            toast("Dispute URL copied to clipboard")
                                                        }}>
                                                        <span className="fa fa-clipboard"/> copy
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </>}
                                    </Colxx>
                                </Row>
                                <hr/>

                            </>)
                        }

                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }

}