import React, {Component} from "react";
import ReactDOM from "react-dom";
import {NavItem, UncontrolledCollapse} from "reactstrap";
import {NavLink, withRouter} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import {connect} from "react-redux";
import {addContainerClassname, changeDefaultClassnames, setContainerClassnames} from "../../redux/actions";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.addEvents = this.addEvents.bind(this);
    this.removeEvents = this.removeEvents.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleProps = this.handleProps.bind(this);
    this.getContainer = this.getContainer.bind(this);
    this.getMenuClassesForResize = this.getMenuClassesForResize.bind(this);
    this.setSelectedLiActive = this.setSelectedLiActive.bind(this);

    this.state = {
      selectedParentMenu: "",
      viewingParentMenu: ""
    };
  }

  handleWindowResize(event) {
    if (event && !event.isTrusted) {
      return;
    }
    const { containerClassnames } = this.props;
    let nextClasses = this.getMenuClassesForResize(containerClassnames);
    this.props.setContainerClassnames(0, nextClasses.join(" "));
  }

  handleDocumentClick(e) {
    const container = this.getContainer();
    let isMenuClick = false;
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains("menu-button") ||
        e.target.classList.contains("menu-button-mobile"))
    ) {
      isMenuClick = true;
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      (e.target.parentElement.classList.contains("menu-button") ||
        e.target.parentElement.classList.contains("menu-button-mobile"))
    ) {
      isMenuClick = true;
    } else if (
      e.target.parentElement &&
      e.target.parentElement.parentElement &&
      e.target.parentElement.parentElement.classList &&
      (e.target.parentElement.parentElement.classList.contains("menu-button") ||
        e.target.parentElement.parentElement.classList.contains(
          "menu-button-mobile"
        ))
    ) {
      isMenuClick = true;
    }
    if (container.contains(e.target) || container === e.target || isMenuClick) {
      return;
    }
    this.toggle(e);
    this.setState({
      viewingParentMenu: ""
    });
  }

  getMenuClassesForResize(classes) {
    const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.props;
    let nextClasses = classes.split(" ").filter(x => x != "");
    const windowWidth = window.innerWidth;
    if (windowWidth < menuHiddenBreakpoint) {
      nextClasses.push("menu-mobile");
    } else if (windowWidth < subHiddenBreakpoint) {
      nextClasses = nextClasses.filter(x => x != "menu-mobile");
      if (
        nextClasses.includes("menu-default") &&
        !nextClasses.includes("menu-sub-hidden")
      ) {
        nextClasses.push("menu-sub-hidden");
      }
    }
    return nextClasses;
  }

  getContainer() {
    return ReactDOM.findDOMNode(this);
  }

  toggle() {
    const { containerClassnames, menuClickCount } = this.props;
    const currentClasses = containerClassnames
      ? containerClassnames.split(" ").filter(x => x != "")
      : "";

    if (currentClasses.includes("menu-sub-hidden") && menuClickCount == 2) {
      this.props.setContainerClassnames(2, containerClassnames);
    } else if (
      currentClasses.includes("menu-hidden") ||
      currentClasses.includes("menu-mobile")
    ) {
      this.props.setContainerClassnames(0, containerClassnames);
    }
  }

  handleProps() {
    this.addEvents();
  }

  addEvents() {
    ["click", "touchstart"].forEach(event =>
      document.addEventListener(event, this.handleDocumentClick, true)
    );
  }
  removeEvents() {
    ["click", "touchstart"].forEach(event =>
      document.removeEventListener(event, this.handleDocumentClick, true)
    );
  }
  setSelectedLiActive() {
    const oldli = document.querySelector(".sub-menu  li.active");
    if (oldli != null) {
      oldli.classList.remove("active");
    }

    /* set selected parent menu */
    const selectedlink = document.querySelector(".sub-menu  a.active");
    if (selectedlink != null) {
      selectedlink.parentElement.classList.add("active");
      this.setState({
        selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute(
          "data-parent"
        )
      });
    } else {
      var selectedParentNoSubItem = document.querySelector(
        ".main-menu  li a.active"
      );
      if (selectedParentNoSubItem != null) {
        this.setState({
          selectedParentMenu: selectedParentNoSubItem.getAttribute("data-flag")
        });
      } else if (this.state.selectedParentMenu == "") {
        this.setState({
          selectedParentMenu: "dashboards"
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setSelectedLiActive();
      this.toggle();
      window.scrollTo(0, 0);
    }
    this.handleProps();
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize();
    this.handleProps();
    this.setSelectedLiActive();
  }

  componentWillUnmount() {
    this.removeEvents();
    window.removeEventListener("resize", this.handleWindowResize);
  }

  changeDefaultMenuType(e, containerClassnames) {
    e.preventDefault();
    let nextClasses = this.getMenuClassesForResize(containerClassnames);
    this.props.setContainerClassnames(0, nextClasses.join(" "));
  }

  changeViewingParentMenu(menu) {
    this.toggle();
  }

  render() {
    return (
      <div className="sidebar">
        <div className="main-menu">
          <PerfectScrollbar
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <div className="scroll">
              <nav className="ml-4 mt-5">

                {/* ************************************
                API Hooks Menu
                ************************************** */}
                <div className="offcanvas-main-links">
                  <NavItem id="api-hooks" className="mt-3">
                    <NavLink className="sub-menu-links" to="/app/apiHooks">
                      <i className="fa fa-project-diagram mr-2" />{" "}
                      API Connections

                      {/*<div className="pr-3 float-right">*/}
                      {/*  <i className="fa fa-angle-down color-light" />*/}
                      {/*</div>*/}
                    </NavLink>
                  </NavItem>
                </div>

                {/*<UncontrolledCollapse*/}
                {/*    toggler="#api-hooks"*/}
                {/*    className="list-style-none sub-menu-collapse"*/}
                {/*>*/}

                {/*  <NavItem className="sub-menu-cus ml-2">*/}
                {/*    <NavLink to="/app/main/layout">*/}
                {/*      <i className="far fa-envelope font-color-1 mr-1" />{" "}*/}
                {/*      Setup*/}
                {/*    </NavLink>*/}
                {/*  </NavItem>*/}
                {/*  <NavItem className="sub-menu-cus ml-2">*/}
                {/*    <NavLink to="/app/main/layout">*/}
                {/*      <i className="far fa-envelope font-color-1 mr-1" />{" "}*/}
                {/*      Reports*/}
                {/*    </NavLink>*/}
                {/*  </NavItem>*/}
                {/*</UncontrolledCollapse>*/}


              {/* ************************************
               Email hooks and templates menu
               ************************************** */}

                <div className="offcanvas-main-links">
                  <NavItem id="email-hook" className="mt-2">
                    <NavLink className="sub-menu-links" to="/app/main/layout" style={{textDecoration: "line-through"}}>
                      <i className="far fa-envelope mr-2" />{" "}
                      Email Receivers

                      <div className="pr-3 float-right">
                        <i className="fa fa-angle-down color-light" />
                      </div>
                    </NavLink>
                  </NavItem>
                </div>

                <UncontrolledCollapse
                    toggler="#email-hook"
                    className="list-style-none sub-menu-collapse"
                >
                  <NavItem className="sub-menu-cus ml-2">
                    <NavLink to="/app/main/layout">
                      <i className="far fa-envelope font-color-1 mr-1" />{" "}
                      Setup
                    </NavLink>
                  </NavItem>

                  <NavItem className="sub-menu-cus ml-2">
                    <NavLink to="/app/main/layout">
                      <i className="far fa-envelope font-color-1 mr-1" />{" "}
                      Recipients
                    </NavLink>
                  </NavItem>

                  <NavItem className="sub-menu-cus ml-2">
                    <NavLink to="/app/main/layout">
                      <i className="far fa-envelope font-color-1 mr-1" />{" "}
                      Logs
                    </NavLink>
                  </NavItem>

                  <NavItem className="sub-menu-cus ml-2">
                    <NavLink to="/app/main/layout">
                      <i className="far fa-envelope font-color-1 mr-1" />{" "}
                      Reports
                    </NavLink>
                  </NavItem>

                </UncontrolledCollapse>


              {/* ************************************
               Dialogue Tracking setup and reports
               ************************************** */}

                <div className="offcanvas-main-links">
                  <NavItem id="dialogue-tracking" className="mt-3">
                    <NavLink className="sub-menu-links" to="/app/main/layout"  style={{textDecoration: "line-through"}}>
                      <i className="fa fa-comments mr-2" />{" "}
                      Dialogue Tracking

                      <div className="pr-3 float-right">
                        <i className="fa fa-angle-down color-light" />
                      </div>
                    </NavLink>
                  </NavItem>
                </div>
                <UncontrolledCollapse
                    toggler="#dialogue-tracking"
                    className="list-style-none sub-menu-collapse"
                >

                  <NavItem className="sub-menu-cus ml-2">
                    <NavLink to="/app/main/layout">
                      <i className="far fa-envelope font-color-1 mr-1" />{" "}
                      Setup
                    </NavLink>
                  </NavItem>
                  <NavItem className="sub-menu-cus ml-2">
                    <NavLink to="/app/main/layout">
                      <i className="far fa-envelope font-color-1 mr-1" />{" "}
                      Reports
                    </NavLink>
                  </NavItem>
                </UncontrolledCollapse>


              {/* ************************************
               Promo Management
               ************************************** */}

                <div className="offcanvas-main-links">
                  <NavItem id="promo" className="mt-3">
                    <NavLink className="sub-menu-links" to="/app/promo">
                      <i className="fa fa-trophy mr-2" />{" "}
                      Promo Management

                      <div className="pr-3 float-right">
                        <i className="fa fa-angle-down color-light" />
                      </div>
                    </NavLink>
                  </NavItem>
                </div>
                <UncontrolledCollapse
                    toggler="#promo"
                    className="list-style-none sub-menu-collapse"
                >

                  <NavItem className="sub-menu-cus ml-2">
                    <NavLink to="/app/promo/list">
                      <i className="fa fa-stream font-color-1 mr-1" />{" "}
                      Promo List
                    </NavLink>
                  </NavItem>
                  <NavItem className="sub-menu-cus ml-2">
                    <NavLink to="/app/main/layout">
                      <i className="fa fa-qrcode font-color-1 mr-1" />{" "}
                      Codes
                    </NavLink>
                  </NavItem>
                  <NavItem className="sub-menu-cus ml-2">
                    <NavLink to="/app/promo/entries">
                      <i className="fa fa-ticket-alt font-color-1 mr-1" />{" "}
                      Entries
                    </NavLink>
                  </NavItem>

                  <NavItem className="sub-menu-cus ml-2">
                    <NavLink to="/app/promo/disputes">
                      <i className="fa fa-exclamation-triangle font-color-1 mr-1" />{" "}
                      Disputes
                    </NavLink>
                  </NavItem>
                </UncontrolledCollapse>

                {/* ************************************
               User Management
               ************************************** */}

                <div className="offcanvas-main-links">
                  <NavItem id="users" className="mt-3">
                    <NavLink className="sub-menu-links" to="/app/users">
                      <i className="fa fa-users mr-2" />{" "}
                      Users

                      {/*<div className="pr-3 float-right">*/}
                      {/*  <i className="fa fa-angle-down color-light" />*/}
                      {/*</div>*/}
                    </NavLink>
                  </NavItem>
                </div>
              </nav>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ menu }) => {
  const {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount
  } = menu;
  return {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    {
      setContainerClassnames,
      addContainerClassname,
      changeDefaultClassnames
    }
  )(Sidebar)
);
