import React, {Component} from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardTitle,
    CustomInput,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table
} from "reactstrap";
import ApiClient from "../../api/ApiClient";
import {clearAuthTokens} from "axios-jwt";
import "../../assets/css/bootstrap.extra.css";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import EntryDetail from "./entryDetail";
import moment from "moment";
import {dictToRows, keyValueToRow} from "../../util/RenderUtils";


class DisputeList extends Component {
    disputeApi = new ApiClient('dispute', this.authenticationErrorHandler.bind(this))
    promoApi = new ApiClient('promo', this.authenticationErrorHandler.bind(this))

    state = {
        disputes: [],
        limit: 20,
        page: 1,
        hasPrevious: false,
        hasNext: false,
        totalCount: 0,
        filters: {
            // has_attachment_only: false,
            include_invalid: true
        },
        filterOptions: {},

        promoTitle: '',

        sort: '',

        modalDispute: null,
        selectedModalDisputeAction: null
    }

    constructor(props) {
        super(props);

        this.history = props.history;
    }

    fetchPromoName(promoID) {
        if (promoID) {
            this.promoApi.getItem(promoID).then(response => {
                this.setState({promoTitle: response.data.title})
            })
        } else {
            this.setState({promoTitle: ""})
        }
    }

    fetchDisputes() {
        const params = {
            limit: this.state.limit,
            offset: (this.state.page - 1) * this.state.limit,
            ...this.state.filters
        }

        const { match } = this.props;
        const promoID = match.params.promoID

        if (promoID) {
            params["promo_id"] = promoID
        }
        if (this.state.sort) {
            params['sort'] = this.state.sort
        }

        params['is_resolved'] = false;

        this.fetchPromoName(promoID)

        this.disputeApi.list({params: params}).then(response => {
            this.setState({
                hasNext: !!response.data.next,
                hasPrevious: !!response.data.previous,
                disputes: response.data.results,
                totalCount: response.data.count,
                // modalDispute: response.data.results.slice(-1)[0]  // TODO: remove this
            })
        })
    }

    fetchFilters() {
        const { match } = this.props;
        const promoID = match.params.promoID

        const params = {}
        if (promoID) {
            params["promo_id"] = promoID
        }

        this.disputeApi.getItem('filters', {params: params}).then(response => {
            this.setState({filterOptions: response.data})
        })
    }

    componentDidMount() {
        this.fetchDisputes()
        this.fetchFilters()
    }

    authenticationErrorHandler() {
        clearAuthTokens();
        this.history.replace('/login/')
    }

    goToPage(pageNumber) {
        this.setState({page: pageNumber}, () => {
            this.fetchDisputes()
        })
    }

    renderPagination() {
        const pageCount = Math.ceil(this.state.totalCount / this.state.limit);
        const visiblePages = [];

        let startPageCounting = this.state.page - 2 > 0 ? this.state.page - 2 : 1;
        let endPageCounting = startPageCounting + 5 <= pageCount ? startPageCounting + 5 : pageCount ;
        if (endPageCounting - startPageCounting < 5) {
            startPageCounting = endPageCounting - 5 > 0 ? endPageCounting - 5 : 1
        }

        while (startPageCounting <= endPageCounting) {
            visiblePages.push(startPageCounting)
            startPageCounting ++;
        }

        return <Pagination size="sm" aria-label="Page navigation example" className="justify-content-center">
            <PaginationItem disabled={!this.state.hasPrevious} onClick={() => {this.goToPage(1)}}>
                <PaginationLink
                    className="fa fa-angle-double-left"
                />
            </PaginationItem>
            <PaginationItem disabled={!this.state.hasPrevious}  onClick={() => {this.goToPage(this.state.page - 1)}}>
                <PaginationLink className="fa fa-angle-left" />
            </PaginationItem>
            {
                visiblePages.map(
                    p => <PaginationItem
                        key={"page-"+p} active={p === this.state.page}
                        onClick={() => {p !== this.state.page && this.goToPage(p)}}
                    >
                        <PaginationLink>{p}</PaginationLink>
                    </PaginationItem>
                )
            }
            <PaginationItem  disabled={!this.state.hasNext}  onClick={() => {
                this.goToPage(this.state.page + 1)
            }}>
                <PaginationLink
                    className="fa fa-angle-right"
                />
            </PaginationItem>
            <PaginationItem  disabled={!this.state.hasNext} onClick={() => {
                this.goToPage(pageCount)
            }}>
                <PaginationLink
                    className="fa fa-angle-double-right"
                />
            </PaginationItem>
        </Pagination>
    }

    handleChangeFilterValue(fullFilterKey, filterOption) {
        this.setState(prevState => {
            return {
                ...prevState,
                filters: {
                    ...prevState.filters,
                    [fullFilterKey]: filterOption ? filterOption.value : undefined
                }
            }
        })
    }

    toggleSort(fieldName) {
        this.setState(prevState => {
            let sortValue = ''
            if (prevState.sort === fieldName) {
                sortValue = '-' + fieldName
            } else if (prevState.sort === '-' + fieldName) {
                sortValue = ''
            } else {
                sortValue = fieldName
            }
            return {
                ...prevState,
                sort: sortValue
            }
        }, () => {
            this.fetchDisputes()
        })
    }

    toggleModalDispute() {
        this.setState({modalDispute: null})
    }

    renderFilter(filterDefinition) {
        const filterKey = filterDefinition.key
        const fullFilterKey = (filterDefinition.is_raw ? 'raw_data__' : '') + filterKey

        const selectedOptionValue = this.state.filters[fullFilterKey]
        const selectedOption = selectedOptionValue ? filterDefinition.values.filter(options => options.value === selectedOptionValue)[0] : null
        const displayKey = filterKey.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());  // titleCase

        // TODO: add download entries
        return <Colxx key={"filter-" + filterKey}>
            <Label>{displayKey}</Label>
            <Select
                isClearable={true}
                components={{ Input: CustomSelect }}
                className="react-select small"
                classNamePrefix="react-select"
                name={"filter-" + filterKey}
                value={selectedOption}
                placeholder={"Select " + displayKey}
                onChange={this.handleChangeFilterValue.bind(this, fullFilterKey)}
                options={filterDefinition.values}
            />
        </Colxx>
    }

    renderFilters() {
        if (Object.keys(this.state.filterOptions).length <= 0) {
            return <i>No filters available...</i>
        }
        return <Row className="mb-4 border-1px py-3">
            <Colxx>
                <Row>
                    <Colxx>
                        <h4>
                            Filters
                        </h4>
                    </Colxx>
                </Row>
                <Row className="mb-4">
                    <Colxx>
                        <select onChange={e => this.setState({limit: e.target.value})}>
                            <option selected={this.state.limit === 10}>10</option>
                            <option selected={this.state.limit === 20}>20</option>
                            <option selected={this.state.limit === 50}>50</option>
                            <option selected={this.state.limit === 100}>100</option>
                        </select> disputes per page
                        {/*<CustomInput*/}
                        {/*    type="checkbox"*/}
                        {/*    id="CustomInlineCheckboxx"*/}
                        {/*    label="Second"*/}
                        {/*    inline*/}
                        {/*/>*/}
                    </Colxx>
                </Row>
                <Row className="align-items-end d-flex">
                    {
                        this.state.filterOptions.map( filterOptionDefinition => this.renderFilter(filterOptionDefinition))
                    }
                    <Colxx md={1} className="mb-1">
                        <Button size="sm" onClick={() => {this.fetchDisputes()}}>
                            <span className="fa fa-search"/>
                        </Button>
                    </Colxx>
                </Row>
            </Colxx>
        </Row>
    }

    renderModalContent() {
        if (!this.state.modalDispute) {
            return <>Loading</>
        }
        return <>
            <ModalHeader toggle={this.toggleModalDispute.bind(this)}>
                Dispute #{this.state.modalDispute.id}
            </ModalHeader>
            <ModalBody>
                {this.state.modalDispute.additional_details && dictToRows(this.state.modalDispute.additional_details)}
                { keyValueToRow(
                    "Datetime Dispute Filed",

                    <span title={moment(this.state.modalDispute.datetime_created).format('LLLL')}>
                        <strong>{moment(this.state.modalDispute.datetime_created).format('LLLL')}</strong>{" "}
                        ({moment(this.state.modalDispute.datetime_created).fromNow()})
                    </span>
                ) }
                <hr/>
                <Row className="mx-4">
                    <Colxx
                        md={6}

                        className={"px-4 " + (
                            !!this.state.selectedModalDisputeAction && this.state.selectedModalDisputeAction.value === 'ignore' ? 'blur' : ''
                        )}>
                        <h5 className="text-danger">Entry raising dispute</h5>
                        <hr/>
                        <EntryDetail entry={this.state.modalDispute.disputed_by_entry}/>
                    </Colxx>
                    <Colxx
                        md={6}
                        className={"px-4 border-left " + (
                            !!this.state.selectedModalDisputeAction && this.state.selectedModalDisputeAction.value === 'approve' ? 'blur' : ''
                        )}>
                        <h5 className="text-success">Entry being disputed</h5>
                        <hr/>
                        <EntryDetail entry={this.state.modalDispute.disputed_entry}/>
                    </Colxx>
                </Row>
                <hr/>
                <Row className="d-flex align-bottom m-2">
                    <Colxx md={7} className="d-flex align-items-center">
                        <strong>{ !this.state.selectedModalDisputeAction ? '' : (
                            this.state.selectedModalDisputeAction.value === 'approve' ?
                                'Approve will mark the entry being disputed as invalid, and mark the entry raising the dispute as valid.' :
                                'Ignore will not do anything but mark this dispute as resolved.'
                        ) }</strong>
                    </Colxx>
                    <Colxx md={4} >

                        <Label><strong>Select Resolution</strong></Label>
                        <Select
                            isClearable={true}
                            components={{ Input: CustomSelect }}
                            className="react-select small"
                            classNamePrefix="react-select"
                            value={this.state.selectedModalDisputeAction}
                            placeholder={"Select Resolution"}
                            onChange={o => this.setState({selectedModalDisputeAction: o})}
                            options={[
                                {label: "Ignore Dispute", value: "ignore"},
                                {label: "Approve Dispute", value: "approve"}
                            ]}
                        />
                    </Colxx>
                    <Colxx md={1} className="d-flex justify-content-end">
                        <Button
                            color={this.state.selectedModalDisputeAction && this.state.selectedModalDisputeAction.value === 'ignore' ? 'danger' : 'success'}
                            disabled={!this.state.selectedModalDisputeAction}>
                            Apply
                        </Button>
                    </Colxx>
                </Row>

            </ModalBody>
        </>
    }

    render() {
        const COL_TO_PROPERTY = {
            // 'ID': 'id',
            'Name': 'name',
            'Email Address': 'email_address',
            'Mobile Number': 'mobile_number',
            // 'Profile ID': 'profile_identifier',
        }

        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h2>{this.state.promoTitle} Disputes</h2>
                        </CardTitle>

                        { this.renderFilters() }

                        <Row>
                            <Colxx xxs="12" className="text-center">
                                {this.renderPagination()}
                            </Colxx>
                        </Row>

                        <Table hover striped>
                            <thead>
                            <tr>
                                <th>Dispute #</th>

                                <th onClick={this.toggleSort.bind(this, 'disputed_by_entry__name')}>
                                    Name
                                    { 'disputed_by_entry__name' === this.state.sort ? <span className="fa fa-caret-up"/> : '' }
                                    { "-disputed_by_entry__name" === this.state.sort ? <span className="fa fa-caret-down"/> : '' }
                                </th>

                                <th onClick={this.toggleSort.bind(this, 'disputed_by_entry__email_address')}>
                                    Email Address
                                    { 'disputed_by_entry__email_address' === this.state.sort ? <span className="fa fa-caret-up"/> : '' }
                                    { "-disputed_by_entry__email_address" === this.state.sort ? <span className="fa fa-caret-down"/> : '' }
                                </th>

                                <th onClick={this.toggleSort.bind(this, 'disputed_by_entry__mobile_number')}>
                                    Mobile Number
                                    { 'disputed_by_entry__mobile_number' === this.state.sort ? <span className="fa fa-caret-up"/> : '' }
                                    { "-disputed_by_entry__mobile_number" === this.state.sort ? <span className="fa fa-caret-down"/> : '' }
                                </th>
                                <th onClick={this.toggleSort.bind(this, 'disputed_by_entry__raw_code')}>
                                    Code
                                    { 'disputed_by_entry__raw_code' === this.state.sort ? <span className="fa fa-caret-up"/> : '' }
                                    { "-disputed_by_entry__raw_code" === this.state.sort ? <span className="fa fa-caret-down"/> : '' }
                                </th>
                                <th onClick={this.toggleSort.bind(this, 'datetime_created')}>
                                    Dispute Date
                                    { 'datetime_created' === this.state.sort ? <span className="fa fa-caret-up"/> : '' }
                                    { "-datetime_created" === this.state.sort ? <span className="fa fa-caret-down"/> : '' }
                                </th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.disputes.map(dispute => {
                                    return <tr
                                        key={"dispute-" + dispute.id}
                                    >
                                        <td>
                                            #{dispute.id}
                                        </td>

                                        <td>
                                            {dispute.disputed_by_entry.name}
                                        </td>
                                        <td>
                                            {dispute.disputed_by_entry.email_address}
                                        </td>
                                        <td>
                                            {dispute.disputed_by_entry.mobile_number}
                                        </td>
                                        <td>
                                            {dispute.disputed_by_entry.raw_code}
                                        </td>
                                        <td title={moment(dispute.datetime_created).format('LLLL')}>
                                            {moment(dispute.datetime_created).fromNow()}
                                        </td>
                                        <td>
                                            <ButtonGroup>
                                            {/*    <Button size="xs" outline onClick={*/}
                                            {/*        () => {*/}
                                            {/*            this.setState({*/}
                                            {/*                modalDispute: JSON.parse(JSON.stringify(entry))*/}
                                            {/*            })*/}
                                            {/*        }*/}
                                            {/*    } disabled={entry.attachments.length <= 0}>*/}
                                            {/*        <span className="fa fa-image"/><br/>*/}
                                            {/*        img*/}
                                            {/*    </Button>*/}
                                                <Button size="xs" outline onClick={
                                                    () => {
                                                        this.setState({
                                                            modalDispute: JSON.parse(JSON.stringify(dispute))
                                                        })
                                                    }
                                                }>
                                                    <span className="fa fa-eye"/><br/>
                                                    view
                                                </Button>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </Table>

                        {this.renderPagination()}

                        <Modal
                            size="xl"
                            isOpen={!!this.state.modalDispute}
                            toggle={this.toggleModalDispute.bind(this)}>
                            {this.renderModalContent()}
                        </Modal>
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }
}

export default DisputeList