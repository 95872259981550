import React, { Component } from "react";
import {Colxx} from "../../components/CustomBootstrap";
import {Button, Card, CardBody, CardTitle, Form, FormGroup, Input, Label, Row} from "reactstrap";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CustomSelect from "../../components/CustomSelect";
import {injectIntl} from "react-intl";
import ApiClient from "../../api/ApiClient";

import moment from "moment";


const DEFAULT_PROMO_DATA = {
    "title": "",
    "description": "",
    "date_start": null,
    "date_end": null,
}


class PromoForm extends Component {
    state = {
        ...DEFAULT_PROMO_DATA,
        originalData: {...DEFAULT_PROMO_DATA}
    }

    promoApi = new ApiClient('promo')

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { match } = this.props;
        const promoID = match.params.promoID

        if (promoID) {
            this.promoApi.getItem(promoID).then(this.processResponseData.bind(this))
        }
    }

    processResponseData(response) {
        const promoData = response.data
        this.setState({...promoData, originalData: {...promoData}})
    }
    
    handleChangeStartDate(date) {
        this.setState({date_start: moment(date).format("YYYY-MM-DD")})
    }

    handleChangeEndDate(date) {
        this.setState({date_end: moment(date).format("YYYY-MM-DD")})
    }

    handleTitleChange(e) {
        this.setState({"title": e.target.value})
    }

    handleDescriptionChange(e) {
        this.setState({"description": e.target.value})
    }

    isDataChanged() {
        const newData = {...this.state}
        const originalData = newData["originalData"]
        delete newData["originalData"]

        const isEqual = JSON.stringify(newData) === JSON.stringify(originalData)

        return !isEqual
    }

    handleSave() {
        const newData = {...this.state}
        delete newData["originalData"]

        const { match } = this.props;
        const promoID = match.params.promoID

        if (promoID) {
            this.promoApi.updateItem(promoID, newData).then(this.processResponseData.bind(this))
        } else {
            this.promoApi.create(newData).then(response => {
                const newPromoId = response.data.id;
                this.props.history.push('/app/promo/' + newPromoId + '/')
            })
        }
    }

    render() {
        const { messages } = this.props.intl;
        const startDate = this.state.date_start && new Date(this.state.date_start);
        const endDate = this.state.date_end && new Date(this.state.date_end);

        return <Row className="mb-4">
            <Colxx xxs="12">
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h2>{this.state.originalData.title ? <span>
                                <span>Editing</span> <strong>{this.state.originalData.title}</strong>
                            </span> : "New Promo" }</h2>
                        </CardTitle>
                        <Form>
                            <Row form>
                                <Colxx md={12}>
                                    <FormGroup>
                                        <Label for="promoTitle">Title</Label>
                                        <Input
                                            type="text"
                                            name="promoTitle"
                                            id="promoTitle"
                                            placeholder="Promo Title"
                                            value={this.state.title}
                                            onChange={this.handleTitleChange.bind(this)}
                                        />
                                    </FormGroup>
                                </Colxx>
                            </Row>
                            <Row form>
                                <Colxx md={12}>
                                    <FormGroup>
                                        <Label for="Description">Description</Label>
                                        <Input
                                            type="textarea"
                                            name="description"
                                            id="description"
                                            placeholder="Promo simple description. This will appear in the registration page."
                                            value={this.state.description}
                                            onChange={this.handleDescriptionChange.bind(this)}
                                        />
                                    </FormGroup>
                                </Colxx>
                            </Row>

                            <Row form>
                                <Colxx lg={6}>
                                    <FormGroup>
                                        <Label for="exampleZip">Promo Start</Label>
                                        <DatePicker
                                            selected={startDate}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={this.handleChangeStartDate.bind(this)}
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </FormGroup>
                                </Colxx>
                                <Colxx lg={6}>
                                    <FormGroup>
                                        <Label for="exampleZip">Promo End</Label>
                                        <DatePicker
                                            selected={endDate}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            onChange={this.handleChangeEndDate.bind(this)}
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </FormGroup>
                                </Colxx>
                            </Row>


                            <Button color="primary" className="mt-2" disabled={!this.isDataChanged()} onClick={this.handleSave.bind(this)}>
                                Save
                            </Button>
                        </Form>
                    </CardBody>
                </Card>
            </Colxx>
        </Row>
    }
}

export default injectIntl(PromoForm);